//
// ONLY EDIT THIS FILE IN /frontend/src/
//

import { storyMaxCharacters } from '../utility'

export const translation = {
  projectName: 'DFB Fussballzeit',

  header: {
    search: {
      placeholder: 'Location, Artist ...',
      cta: 'search',
    },
    navigation: {
      default: [
        {
          name: 'Fussballzeit',
          link: '/#fussballzeit',
        },
        {
          name: 'Participate',
          link: '/#mitmachen',
        },
        {
          name: 'FAQ',
          link: '/#faq',
        },
      ],
      ADMIN: [
        {
          name: 'Moderation',
          link: '/admin/moderate',
        },
        {
          name: 'Public',
          link: '/admin/public',
        },
        {
          name: 'Archive',
          link: '/admin/archive',
        },
        {
          name: 'Export',
          link: '/admin/export',
        },
        {
          name: 'Users',
          link: '/admin/users',
        },
        {
          name: 'Create entry',
          link: '/admin/manual-entry',
        },
      ],
      isLoggedIn: {
        name: 'Sign out',
      },
    },
  },

  index: {
    hero: {
      subline: 'IS THE BEST TIME.',
      text: [
        'The UEFA EURO 2024™ is right around the corner and all of Europe is counting down the days!',
        'Are you too? Then show us what your best FUSSBALLZEIT looks like!',
      ],
      cta: 'Join now',
    },
    gallery: {
      hint: '*We reserve the right to make minor changes to the text structure of all the FUSSBALLZEIT moments in order to ensure consistency.',
    },
    intro: {
      headline: ['Fussballzeit is your best time?', 'Join in and win!'],
      text: 'Do you regularly go to the stadium with your friends? Do you play football with your children in the garden, rain or shine? Do you go to your favourite pub every first Sunday of the month with your regulars? Does your uncle have the biggest collection of football shirts you’ve ever seen?',
      prefix: 'Perfect! Because that is exactly what we are looking for. ',
      suffix:
        ' Stories that make FUSSBALLZEIT the best time ever. Simply upload the photo that best sums up your FUSSBALLZEIT moment, add a short description and join other football fans in bringing our campaign to life.',
      contest: {
        headline: 'What you can win',
        fast: {
          prefix: 'Quick draw: ',
          suffix:
            'If you upload your FUSSBALLZEIT and your contribution is among the first 150 submissions, you win one of 150 DFB jerseys (one size fits all).*',
        },
        special: {
          prefix: 'Special draw: ',
          suffix: 'Among all FUSSBALLZEIT entries received by 31/05/2024, we will give away 10 x 2 tickets to the UEFA EURO 2024™ this summer.',
          hint: '* While supplies last.',
        },
      },
    },
    quote: {
      text: 'Share your personal story with us and be part of the campaign “FUSSBALLZEIT IS THE BEST TIME.”',
      author: 'TOGETHER WE INSPIRE EUROPE!',
      cta: 'Join now',
    },
    deadline: {
      headline: ['Send us by 31/05/2024', 'your most beautiful, exciting or funniest FUSSBALLZEIT story.'],
      insta: {
        title: 'How to get on our Instagram Story',
        upload: {
          text: 'Upload your favourite image and a short description',
        },
        share: {
          prefix: 'Then share the picture with your story on Instagram, using the hashtag #FUSSBALLZEIT and linking to',
          highlight: ' one of our two channels (',
          dfb: 'dfb_team',
          dfbLink: 'https://www.instagram.com/dfb_team/',
          prefixFussball: ' or ',
          fussball: 'fussball_de',
          fussballLink: 'https://www.instagram.com/fussball_de/',
          suffix: ').',
        },
        luck: 'With a bit of luck, you might end up in our story',
      },
    },
    uploadForm: {
      headline: 'Participate',
      loginHint: 'Sign up now and upload your FUSSBALLZEIT moment.',
      success: 'Thank you! Your FUSSBALLZEIT moment has been successfully submitted, will be reviewed, and uploaded promptly.',
      error: {
        uploadFailed: 'You have already participated in the action. Thank you!',
        noFile: 'Please upload your FUSSBALLZEIT moment.',
        processingFailed: 'Your FUSSBALLZEIT moment could not be processed. Please try again.',
        limitReached: 'You have already participated in the action. Thank you!',
        formField: (field: string) => `${field} is a mandatory field.`,
      },
      dnd: {
        text: 'Upload Picture',
        hint: '(Allowed are .jpg and .png up to 5 MB in portrait format)',
        selectedPrefix: 'Selected Picture: ',
        error: {
          size: 'Your selected picture is too large. Maximum allowed file size: 5 MB',
          type: 'Please only upload .jpg or .png files.',
          dpi: 'Please only upload images with a resolution of at least 300 dpi.',
          aspectRatio: 'Please upload a picture that is oriented in portrait format.',
        },
      },
      legal: {
        prefix: 'I agree to the',
        link: {
          text: ' Terms and Conditions ',
          href: '/teilnahmebedingungen',
        },
        suffix: 'of the FUSSBALLZEIT IS THE BEST TIME campaign.',
        over18: 'I confirm that I am at least 18 years old.',
        upload: {
          prefix:
            'I consent to the processing of my personal data within the scope of the FUSSBALLZEIT campaign. The consent is voluntary and can be revoked at any time with effect for the future. For more information, see the ',
          link: {
            text: 'Privacy Policy',
            href: 'https://www.dfb.de/en/misc/privacy-policy/',
          },
          suffix: '.',
        },
      },
      story: {
        placeholderName: 'Maike',
        nameSuffix: ' FUSSBALLZEIT...',
        placeholder: '...began at the age of 2 – in the jersey of KFC Uerdingen.',
        maxCharactersHint: ` (max. ${storyMaxCharacters} characters)`,
      },
      cta: 'Submit',
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          title: 'How can I take part in the campaign?',
          content: 'To take part in the campaign, you can simply share your best FUSSBALLZEIT with us ',
          link: {
            text: 'here',
            href: '/#mitmachen',
          },
          content2:
            '. Just upload your favourite picture and write a short description of what makes FUSSBALLZEIT your best time. You can find more information in the ',
          link2: {
            text: 'terms and conditions',
            href: '/teilnahmebedingungen',
            newTab: true,
          },
          suffix: '.',
        },
        {
          title: 'What can I upload?',
          content:
            'You can share a photo that shows your personal FUSSBALLZEIT. Whether it’s private moments, visits to the stadium or your own actions on the pitch - anything is possible! You can upload JPEGs or PNGs. The data limit is 5MB. Please upload your picture in portrait format, otherwise it cannot be used.',
        },
        {
          title: 'Is there a deadline for entries?',
          content: 'Yes, you can upload your best FUSSBALLZEIT until 31/05/2024. So don’t forget to enter!',
        },
        {
          title: 'What prizes can you win?',
          content: 'Only the best for your FUSSBALLZEIT: We are giving away 10 x 2 tickets to the 2024 UEFA EURO 2024™.',
        },
        {
          title: 'How will the winners be selected?',
          content: 'The winners of the EURO tickets will be selected randomly.',
        },
        {
          title: 'Can I submit more than one picture & FUSSBALLZEIT moments?',
          content: 'No, you can only submit one image and one text to keep the competition fair for all participants.',
        },
        {
          title: 'How will I know if I’ve won?',
          content: 'Winners will be notified directly by us via email, so keep an eye on your inbox.',
        },
        {
          title: 'Who can take part?',
          content:
            'Everyone is welcome to share their best FUSSBALLZEIT with us - from the casual spectator to the hardcore fan to the full-blooded striker. To take part, you must be at least 18 years old and live in Europe, regardless of your gender, religion or nationality.',
        },
        {
          title: 'What happens to the content I submit?',
          content:
            'The content you submit will only be published on the landing page. Please ensure that you own the rights to the content you submit. See our terms and conditions for more information. Only if you share your content on Instagram, use the hashtag #FUSSBALLZEIT and link to one of our two channels - dfb_team or fussball_de - you will have a chance to have your FUSSBALLZEIT moment featured in our story.',
        },
        {
          title: 'Who can I contact if I have any questions?',
          content: 'If you have any questions or need assistance, please contact us at brandmanagement@dfb.de. Our team is here to help you.',
        },
        {
          title: 'Are there any restrictions on the type of content that can be submitted?',
          content:
            'Any content that is considered offensive, discriminatory, violent or otherwise inappropriate will be excluded from participation.',
        },
        {
          title: 'Will my personal details be published?',
          content:
            'We respect your privacy. Therefore, the personal information you provide to us through our promotion will only be used for the purposes of the promotion and will not be shared with third parties. For more information, please see our ',
          link: {
            text: 'privacy policy',
            href: 'https://www.dfb.de/en/misc/privacy-policy/',
            newTab: true,
          },
          suffix: '.',
        },
        {
          title: 'Can I support the campaign even if I don’t take part?',
          content:
            'You can support the campaign by sharing it on your social networks and encouraging your friends and family to take part. Every contribution will help to build excitement for the upcoming European Championship and continue to make FUSSBALLZEIT the best time.',
        },
      ],
    },
  },

  admin: {
    evaluate: {
      headline: 'Update password',
      error: {
        passwordsDontMatch: 'Passwords do not match',
        save: 'Changes could not be saved.',
      },
      cta: 'Change',
    },
    manualEntry: {
      headline: 'Manual Entry',
      info: 'The street and location will be automatically filled with placeholders to not be considered in the CSV export.',
    },
    moderate: {
      headline: 'Moderation',
    },
    public: {
      headline: 'Public',
    },
    archive: {
      headline: 'Archive',
    },
    export: {
      headline: 'Export',
      first150: {
        headline: 'Quick draw (the first 150 submissions)',
        exportNamePrefix: 'Fussballzeit_QuickDraw_150',
      },
      everybody: {
        headline: 'All contacts',
        exportNamePrefix: 'Fussballzeit_All',
      },
      downloadCTA: 'Download CSV',
    },
    list: {
      empty: 'No entries available',
      close: 'Close',
      archive: 'Archive',
      accept: 'Publish',
      sorting: {
        label: 'Sorting:',
        DESC: 'Date descending',
        ASC: 'Date ascending',
      },
      acceptModal: {
        text: {
          prefix: 'Are you sure you want to publish the story of ',
          suffix: '?',
        },
      },
      storyEdit: {
        error: {
          save: 'Changes could not be saved.',
        },
      },
      protocol: {
        headline: 'Protocol',
        from: 'from',
        to: 'to',
        textChange: {
          prefix: 'Text change from',
          previousText: 'Previous text:',
        },
        userPrefix: 'carried out by',
        userFallback: 'System',
        timestampPrefix: 'at',
        types: {
          VIRUS_SCAN: 'Virus scan',
          MODERATION: 'Moderation',
          PUBLISHED: 'Published',
          ARCHIVED: 'Archived',
          QUARANTINE: 'Quarantine',
        },
      },
    },
    users: {
      headline: 'Users',
      list: {
        headline: 'List of users',
        inactive: ' (inactive)',
      },
      create: {
        headline: 'Create user',
        cta: 'Create',
        hint: 'The system does not send an email to the created user. Please inform the user manually. Upon first login, the user will be prompted to set a new password.',
        error: 'User could not be created.',
      },
    },
  },

  langSwitcher: {
    toggleLabel: 'Language',
  },

  login: {
    headline: 'Welcome',
    cta: 'Sign in',
    error: 'Login failed. Wrong email or password.',
  },

  footer: {
    items: [
      {
        title: `© DFB ${new Date().getFullYear()}`,
      },
      // {
      //   title: 'Terms of Use',
      //   href: '/nutzungsbedingungen',
      // },
      {
        title: 'Privacy Policy',
        href: 'https://www.dfb.de/en/misc/privacy-policy/',
      },
      {
        title: 'Legal Notice',
        href: 'https://www.dfb.de/en/misc/imprint/',
      },
      {
        title: 'Terms and Conditions',
        href: '/teilnahmebedingungen',
      },
    ],
    cookieConsent: {
      title: 'Cookie settings',
    },
    social: {
      instagram: 'https://www.instagram.com/dfb_team/',
      linkedin: 'https://www.linkedin.com/company/deutscher-fussball-bund/',
      twitter: 'https://twitter.com/dfb_team',
    },
  },

  terms: {
    headline: 'Terms and conditions "FUSSBALLZEIT is the best time"',
    text: 'The following terms and conditions apply for the DFB campaign "FUSSBALLZEIT is the best time". In addition, the DFB standard provisions apply (e.g. <a href="https://www.dfb.de/en/misc/privacy-policy/" class="underline" target="_blank" rel="noreferrer">DFB data protection declaration</a>).',
    updateDate: 'Status: 29 February 2024',
    items: [
      {
        title: 'DFB-Campaign "FUSSBALLZEIT is the best time"',
        items: [
          'DFB GmbH & Co. KG, Kennedyallee 274, 60528 Frankfurt am Main ("DFB") is responsible for the realisation of the campaign FUSSBALLZEIT is the best time.',
          'Permitted content for the campaign FUSSBALLZEIT is the best time is described and available on the website <b>fussballzeit.dfb.de</b>.',
        ],
      },
      {
        title: 'Registration and Participation',
        items: [
          'The FUSSBALLZEIT is the best time campaign is open to all natural persons residing in Germany who have reached the age of 18 at the start of the campaign .',
          'Participation requires successful registration at mein.dfb.de.',
          'As part of the campaign, participants should upload photos and submit texts that relate to the FUSSBALLZEIT is the best time campaign. ',
          'Participants are not entitled to have their uploaded content published on the DFB website and/or its social media channels. The DFB will select which content will be published.',
          "The DFB reviews the uploaded/submitted content. The DFB will exclude illegal content from the campaign or any content that contradicts DFB's values. ",
          'There is no legal entitlement to participate in the FUSSBALLZEIT is the best time campaign. The DFB may refuse participation in the FUSSBALLZEIT is the best time campaign without giving reasons. ',
          'Participation begins with uploading a photo and submitting a text, why FUSSBALLZEIT is the best time. The photo and text can be uploaded/submitted on the website fussballzeit.dfb.de up to and including 31 May 2024.',
          'The text may be up to 150 characters. If the DFB publishes the uploaded photo and the text submitted, this will be done by stating the participant’s user name/ name of the participant. ',
        ],
      },
      {
        title: 'Rights of use',
        items: [
          'The participant assures that he/she has the necessary rights to the uploaded photo and/or submitted text. The participant shall obtain the consent of other natural persons who are recognisable in the photos for the publication of the photos.',
          'The participant grants the DFB the non-exclusive, irrevocable, transferable, sub-licensable right, unlimited in time, to use the uploaded/submitted content (photo and text) in full or in part and the right to use the content (photo and text) in full or in extracts. The right to use the content also extends to the publication of the posted content on other portals of the DFB and its member associations.',
          'Before uploading/submitting any content, participants undertake to check whether it contains information that they do not wish to publish. This uploaded/submitted content may be recorded in search engines and therefore can be accessible worldwide. Legal actions against DFB for deletion or correction of such search engine are excluded. ',
          'The participant indemnifies DFB against all claims by third parties that are based on unlawful use of the information, services and data provided or with its approval, or that arise in particular from data protection, copyright, art copyright or other legal disputes associated with participation in the DFB campaign.',
          'If a participant becomes aware that unauthorised use of a DFB account or any other violation of the terms and conditions has occurred or is suspected, the participant shall inform the DFB immediately.',
        ],
      },
      {
        title: 'Period of the "FUSSBALLZEIT is the best time campaign"',
        items: [
          'The DFB-campaign FUSSBALLZEIT is the best time takes place in the period from 09/03/2024 up to and including 15.07.2024 ("Period").',
          'Photos and texts can only be uploaded/submitted up to and including May 31, 2024 on the website fussballzeit.dfb.de.',
        ],
      },
      {
        title: 'Prize draws',
        items: [
          'Only participants will have the opportunity to take part in prize draws during the Period. ',
          'DFB will raffle off 10x2 tickets for the UEFA European Championship 2024 among all participants who have uploaded a photo and submitted a text by  May 31, 2024. The winners will be selected at random (software solution).',
          'The DFB reserves the right to organise further prize draws during the FUSSBALLZEIT is the best time campaign. The winners of the other prize draws will be selected at random by the organiser.',
          'After each prize draw, the successful participant will be informed via the e-mail address provided during registration. If it is not possible to notify the winner via the e-mail address provided, the claim to the prize will lapse and a new winner will be drawn. ',
          'The DFB reserves the right to change or extend the prizes up to the start of the draw. ',
        ],
      },
      {
        title: 'Ancillary costs, taxes, fees',
        items: [
          'Any incidental costs incurred, such as taxes, shipping costs, which are associated with claiming a prize as part of the prize draw, will be borne by the DFB.',
        ],
      },
      {
        title: 'Termination of participation',
        items: [
          "Participants can cancel their participation in the FUSSBALLZEIT is the best time campaign at any time without notice. An e-mail to brandmanagement@dfb.de is sufficient. In such a case, the participant waives participation in the prize draws. Upon termination of participation, photos and texts that have not yet been published will be deleted at the participant's request.",
        ],
      },
      {
        title: 'End of the "FUSSBALLZEIT is the best time" campaign',
        items: [
          'The DFB reserves the right to discontinue the FUSSBALLZEIT is the best time campaign at any time and/or to replace it with another program.',
        ],
      },
      {
        title: 'Exclusion from participation',
        items: [
          'The DFB reserves the right to exclude participants from taking part in the promotion and the prize draw(s) if they upload/submit content in an improper manner. ',
        ],
      },
      {
        title: 'Liability, data protection',
        items: [
          'The following applies to damages incurred by participants in connection with their participation in the FUSSBALLZEIT is the best time campaign: In the event of wilful intent or gross negligence and in the event of a guarantee, liability is unlimited. In the event of slight negligence, liability is also unlimited in the event of injury to life, limb or health. In the event of a slightly negligent breach of material contractual obligations, liability shall be limited to the foreseeable, typically occurring damage to property and financial loss resulting therefrom. An essential contractual obligation is an obligation whose fulfilment is essential for the proper execution of the contract and on whose compliance the participant may regularly rely. Any further liability for damages - except for claims under the Product Liability Act - is excluded. ',
          'The participant\'s personal data associated with participation will be collected, processed and used by the DFB for the purpose of organising the FUSSBALLZEIT is the best time campaign. Further information on data processing in connection with the realisation of the campaign FUSSBALLZEIT is the best time can be found at <a href="https://www.dfb.de/datenschutzerklaerung" class="underline" target="_blank" rel="noreferrer">https://www.dfb.de/datenschutzerklaerung</a>.',
          'This data includes, in particular, the data provided by the participant when registering and then in the course of his/her participation as well as the data collected in connection with his/her participation.',
        ],
      },
      {
        title: 'Changes to the terms and conditions',
        items: [
          'The DFB reserves the right to make changes or additions to the terms and conditions at any time, provided that the participant is not disadvantaged by this contrary to good faith.',
          'Claims for damages by participants against the DFB due to legal country-specific changes are excluded.',
          'Amendments or additions to these conditions of participation will be announced by notification in text form. The amendments or additions to the conditions of participation shall be deemed approved if a participant does not object in text form within two weeks of notification or continues his/her participation in the program. The operators will make special reference to this consequence upon notification. If a participant objects to the amendment or addition, their participation may be terminated in accordance with the conditions of participation.',
        ],
      },
      {
        title: 'Governing Law, place of jurisdiction',
        items: [
          'German law shall apply. The place of jurisdiction is Frankfurt am Main. In addition, a participant may also be sued at his/her general place of jurisdiction or at any other place where legal jurisdiction exists. The DFB is not obliged to participate in arbitration proceedings.',
        ],
      },
      {
        title: 'Enquiries',
        items: ['Any enquiries about the DFB campaign "FUSSBALLZEIT is the best time" can be sent to brandmanagment@dfb.de'],
      },
      {
        title: 'German version',
        items: [
          'In the event of a legal conflict between the German and the English version of the terms and conditions of the DFB campaign "FUSSBALLZEIT is the best time", the German version will prevail.',
        ],
      },
    ],
  },

  generic: {
    salutation: 'Salutation',
    firstname: 'First Name',
    lastname: 'Last Name',
    firstnameSuffix: 's',
    firstnameSpecialSuffix: "'",
    birthday: 'Date of Birth',
    street: 'Street, House Number',
    companyName: 'Company Name',
    club: 'Club (optional)',
    story: 'Story',
    storyTextEdit: {
      cta: 'Edit',
    },
    legalCheck: 'Terms and Conditions',
    over18Check: 'Age Verification',
    uploadLegalCheck: 'Consent to Data Processing',
    city: 'Postal Code, City',
    mail: 'E-Mail Address',
    password: 'Password',
    passwordRepeat: 'Repeat Password',
    createdOn: 'Created On',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    send: 'Send',
    error: 'An unexpected error has occurred. Please try again later.',
    acceptAGB: 'I accept the T&C',
    percent: '%',
    upload: 'Upload',
    login: 'Login',
    logout: 'Logout',
  },

  UI: {
    autocomplete: {
      searchError: 'Search could not be executed.',
      searchEmpty: 'No results',
    },
    dropdown: {
      emptySelection: 'Please select',
    },
    selectableTagList: {
      showMore: 'Show more',
      showLess: 'Less',
    },
    textarea: {
      max: {
        prefix: 'maximum ',
        suffix: ' characters',
      },
    },
    fileSelect: {
      fileToLargePrefix: 'The selected file is too large: ',
      allowedFileTypes: 'Allowed file types: ',
      allowedFileSize: 'Max. allowed file size: ',
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwords do not match',
    passwordReset: 'Password has been successfully changed',
    save: 'Change',
    password: 'Password',
    passwordRepeat: 'Repeat Password',
    title: 'Change Password',
  },
}

export const langKey = 'EN'

const Default = {
  translation,
  langKey,
  word: 'English',
}

export default Default
