import { useEffect, useState } from 'react'
import { MoveProtocol, Type } from '../../../../types/MoveProtocol'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import Loading from '../../Loading/Loading'
import { getPrettyDateTime } from '../../../../utility'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

type Props = {
  storyUUID: string
}

const Item: React.FC<{ moveProtocol: MoveProtocol }> = ({ moveProtocol }) => {
  const t = useSelector((s) => s[props.TRANSLATION])

  if (moveProtocol.to === Type.TEXT_CHANGE) {
    return (
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row md:gap-2">
          <div>{t.admin.list.protocol.textChange.prefix}</div>
          <div className="font-bold">{moveProtocol.userMail || t.admin.list.protocol.userFallback}</div>
          <div>{t.admin.list.protocol.timestampPrefix}</div>
          <div className="font-bold">{getPrettyDateTime(new Date(moveProtocol.createdOn))}</div>
        </div>
        <div className="flex flex-col md:flex-row md:gap-2">
          <div>{t.admin.list.protocol.textChange.previousText}</div>
          <div>{moveProtocol.text}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col md:flex-row md:gap-2">
      <div>{t.admin.list.protocol.from}</div>
      <div className="font-bold">{t.admin.list.protocol.types[moveProtocol.from]}</div>
      <div>{t.admin.list.protocol.to}</div>
      <div className="font-bold">{t.admin.list.protocol.types[moveProtocol.to]}</div>
      <div>{t.admin.list.protocol.userPrefix}</div>
      <div className="font-bold">{moveProtocol.userMail || t.admin.list.protocol.userFallback}</div>
      <div>{t.admin.list.protocol.timestampPrefix}</div>
      <div className="font-bold">{getPrettyDateTime(new Date(moveProtocol.createdOn))}</div>
    </div>
  )
}

const Protocol: React.FC<Props> = ({ storyUUID }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [moveProtocol, setMoveProtocol] = useState<MoveProtocol[]>(null)
  const getMoveProtocol = functional.use(Routes.GET_MOVE_PROTOCOL)

  useEffect(() => {
    const get = async () => {
      const moveProtocol = await getMoveProtocol({ storyUUID })
      if (moveProtocol) {
        setMoveProtocol(moveProtocol)
      }
    }
    if (!moveProtocol) {
      get()
    }
  }, [storyUUID, moveProtocol, getMoveProtocol])

  return (
    <div className="mt-3">
      <div className="text-lg">{t.admin.list.protocol.headline}</div>
      <Loading loading={moveProtocol} />
      <div className="flex flex-col gap-1 divide-y divide-gray-primary">
        {moveProtocol && moveProtocol.map((p) => <Item key={p.uuid} moveProtocol={p} />)}
      </div>
    </div>
  )
}

export default Protocol
