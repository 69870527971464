import { NavigationItem } from '../../../types'

type Props = {
  data: NavigationItem
  className?: string
  contrast?: boolean
  mobile?: boolean
  onClick?: () => void
}

const Default: React.FC<Props> = ({ data, className = '', contrast = false, mobile = false, onClick = null }) => {
  const clicked = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <div className={`flex flex-row gap-1 items-center ${className}`} onClick={clicked}>
      <a
        href={data.link || ''}
        className={`${mobile && 'text-white font-bold text-lg'} ${
          contrast ? 'text-green-primary' : 'text-black'
        } uppercase no-underline hover:text-green-primary hover:underline`}
      >
        {data.name}
      </a>
    </div>
  )
}

export default Default
