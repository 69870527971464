import { useSelector } from 'react-redux'
import MediumContainer from '../../UI/Container/MediumContainer'
import Headline from '../../UI/Headline/Headline'
import useListStories from '../../hooks/useListStories'
import props from '../../../redux/props'
import List from '../../UI/List/List'
import { Mode } from '../../UI/List/types'

const Moderate = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const list = useListStories({ active: false, archived: false })

  return (
    <MediumContainer className="mb-20 pt-10">
      <Headline>
        {t.admin.moderate.headline}
        {Array.isArray(list) && list.length > 0 ? ` (${list.length})` : ''}
      </Headline>
      <List mode={Mode.MODERATE} stories={list} />
    </MediumContainer>
  )
}

export default Moderate
