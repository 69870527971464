import { useSelector } from 'react-redux'
import MediumContainer from '../../UI/Container/MediumContainer'
import Headline from '../../UI/Headline/Headline'
import props from '../../../redux/props'
import useListUsers from '../../hooks/useListUsers'
import Loading from '../../UI/Loading/Loading'
import { User } from '../../../types/User'
import Create from './Create'

const Item: React.FC<{ user: User }> = ({ user }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return <div>{`${user.mail}${!!user.inactive ? t.admin.users.list.inactive : ''}`}</div>
}

const Users = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [list, addUser] = useListUsers()

  return (
    <MediumContainer className="mb-20 pt-10">
      <Headline>{t.admin.users.headline}</Headline>
      <Create userCallback={addUser} />
      <Loading loading={list} />
      {Array.isArray(list) && (
        <>
          <div className="text-lg mt-5">{t.admin.users.list.headline}</div>
          <div className="flex flex-col gap-1">
            {list.map((u) => (
              <Item key={u.uuid} user={u} />
            ))}
          </div>
        </>
      )}
    </MediumContainer>
  )
}

export default Users
