import de from '../translations/de'
import en from '../translations/en'

export type LangKey = string
type Translation = { [key: string]: any }

export type Lang = {
  langKey: LangKey
  translation: Translation
  word: string
}

export type DefaultState = {
  TRANSLATION: { [key: string]: any }
  CURRENTLANG: String
  LANGUAGES: Lang[]
}

const defaultState: DefaultState = {
  TRANSLATION: de.translation,
  CURRENTLANG: de.langKey,
  LANGUAGES: [de, en],
}

export default defaultState
