import { allowedFileMimeTypes, maxFileSizeInBytes, minDPIAmount } from '../../../utility'

export const checkDPI = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()

      img.onload = () => {
        const dpi = img.naturalWidth / 300 // estimated width on screen

        resolve(dpi * 100 >= minDPIAmount)
      }
      img.src = e.target.result as string
    }
    reader.readAsDataURL(file)
  })
}

export const checkFileSize = (file: File) => file.size <= maxFileSizeInBytes

export const checkFileMimeType = (file: File) => allowedFileMimeTypes.includes(file.type)

export const checkAspectRatio = (file: File) => {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()

      img.onload = () => {
        resolve(img.naturalHeight > img.naturalWidth)
      }
      img.src = e.target.result as string
    }
    reader.readAsDataURL(file)
  })
}
