import { useState } from 'react'
import Section from './Section/Section'

type Props = {
  items: {
    title: React.ReactNode | string
    content: React.ReactNode | string
    link?: { text: string; href: string; newTab?: boolean }
    content2: React.ReactNode | string
    link2?: { text: string; href: string; newTab?: boolean }
    suffix?: string
  }[]
  className?: string
  fitTitle?: boolean
}

const Accordion: React.FC<Props> = ({ items, className = '', fitTitle = false }) => {
  const [openIndex, setOpenIndex] = useState<number>(null)

  const toggle = (index: number) => () => setOpenIndex(openIndex === index ? null : index)

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      {items.map(({ title, content, link, content2, link2, suffix }, i) => (
        <Section
          key={i}
          open={openIndex === i}
          title={title}
          content={
            <div>
              {content}
              {link ? (
                <a href={link.href} target={!!link.newTab ? '_blank' : '_self'} rel="noreferrer" className="hover:underline">
                  {link.text}
                </a>
              ) : (
                ''
              )}
              {content2 ? content2 : ''}
              {link2 ? (
                <a href={link2.href} target={!!link2.newTab ? '_blank' : '_self'} rel="noreferrer" className="hover:underline">
                  {link2.text}
                </a>
              ) : (
                ''
              )}
              {suffix ? suffix : ''}
            </div>
          }
          clickedCallback={toggle(i)}
          isLast={i === items.length - 1}
          isFirst={i === 0}
          fitTitle={fitTitle}
        />
      ))}
    </div>
  )
}

export default Accordion
