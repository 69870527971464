import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import theme from './theme'
import Index from './components/Index/Index'
import Login from './components/Login/Login'
import Terms from './components/Terms/Terms'
import Admin from './components/Admin/Admin'
import Evaluate from './components/Admin/Evaluate/Evaluate'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {/* auth */}
            <Route index element={<Index />} />
            <Route path={'test'} element={<Navigate replace to="/" />} />
            <Route path={'login'} element={<Login />} />

            {/* admin */}
            <Route path={'admin/*'} element={<Admin />} />
            <Route path={'admin/evaluate'} element={<Evaluate />} />

            {/* legal */}
            <Route path={'teilnahmebedingungen'} element={<Terms />} />

            {/* fallback routing */}
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  )
}

export default App
