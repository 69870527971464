export enum Type {
  VIRUS_SCAN = 'VIRUS_SCAN',
  MODERATION = 'MODERATION',
  PUBLISHED = 'PUBLISHED',
  ARCHIVED = 'ARCHIVED',
  QUARANTINE = 'QUARANTINE',
  TEXT_CHANGE = 'TEXT_CHANGE',
}

export type MoveProtocol = {
  uuid: string
  accountLinkUUID?: string
  storyUUID: string
  from?: Type
  to: Type
  text?: string
  createdOn: Date
  userMail?: string
}
