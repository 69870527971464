import { useEffect, useState } from 'react'
import { Story } from '../../../types/Story'
import useGetGallery from '../../hooks/useGetGallery'
import Slider from './Slider'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Container from '../../UI/Container/Container'
import { getUUID, totalStoriesInGallery } from '../../../utility'
import _ from 'lodash'

const Gallery: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [stacks, setStacks] = useState<[Story[], Story[], Story[]]>()
  const stories = useGetGallery()

  useEffect(() => {
    const fillGallery = (stories: Story[]): Story[] => {
      const fullGallery: Story[] = _.cloneDeep(stories.slice(0, totalStoriesInGallery))

      if (stories.length < totalStoriesInGallery && stories.length > 0) {
        if (stories.length > totalStoriesInGallery / 2) {
          const missing = totalStoriesInGallery - stories.length
          fullGallery.push(..._.cloneDeep(stories).slice(0, missing))
        } else {
          const fullCopyAmount = Math.floor(totalStoriesInGallery / stories.length)
          for (let i = 0; i < fullCopyAmount; i++) {
            if (i === fullCopyAmount - 1) {
              fullGallery.push(..._.cloneDeep(stories).slice(0, totalStoriesInGallery % stories.length))
            } else {
              fullGallery.push(..._.cloneDeep(stories))
            }
          }
        }
      }

      return fullGallery.map((story) => {
        story.id = getUUID()
        return story
      })
    }

    if (!!stories && !stacks) {
      const filledStories = fillGallery(stories)

      const stack1: Story[] = filledStories.slice(0, filledStories.length / 3)
      const stack2: Story[] = filledStories.slice(filledStories.length / 3, (filledStories.length / 3) * 2)
      const stack3: Story[] = filledStories.slice((filledStories.length / 3) * 2, filledStories.length)

      setStacks([stack1, stack2, stack3])
    }
  }, [stories, stacks])

  return (
    <div className="flex flex-col gap-8 mt-16 ">
      <Slider stories={stacks ? stacks[0] : []} />
      <Slider stories={stacks ? stacks[1] : []} rtl />
      <Slider stories={stacks ? stacks[2] : []} />
      <Container>
        <div className="text-xs text-gray-placeholder">{t.index.gallery.hint}</div>
      </Container>
    </div>
  )
}

export default Gallery
