import { useSelector } from 'react-redux'
import Container from '../UI/Container/Container'
import Headline from '../UI/Headline/Headline'
import Layout from '../UI/Layout/Layout'
import props from '../../redux/props'

const Terms = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <Layout authLevelRequired={false}>
      <div className="my-36">
        <Container>
          <Headline className="text-center text-xl font-bold">{t.terms.headline}</Headline>
          <div className="mt-3" dangerouslySetInnerHTML={{ __html: t.terms.text }}></div>
          <div className="mt-6">
            {t.terms.items.map(({ title, items }, i: number) => (
              <div className="mt-2" key={i}>
                <div className="font-bold">
                  {i + 1}. {title}
                </div>
                <ul className="list-decimal ml-10">
                  {items.map((item: string, j: number) => (
                    <li key={j} dangerouslySetInnerHTML={{ __html: item }}></li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          {!!t.terms.genderHint && <div className="text-xs mt-5">{t.terms.genderHint}</div>}
          <div className="text-right font-bold mt-5">{t.terms.updateDate}</div>
        </Container>
      </div>
    </Layout>
  )
}

export default Terms
