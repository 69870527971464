import { useEffect, useState } from 'react'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { User } from '../../types/User'

type AddUser = (user: User) => void

const useListUsers = (): [User[] | null, AddUser] => {
  const [users, setUsers] = useState<User[]>()
  const listUsers = functional.use(Routes.LIST_USERS)

  useEffect(() => {
    const list = async () => {
      const users = await listUsers()
      setUsers(users)
    }
    if (!users) {
      list()
    }
  }, [users, listUsers])

  const addUser = (user: User) => {
    setUsers((prev) => [user, ...prev])
  }

  return [users, addUser]
}

export default useListUsers
