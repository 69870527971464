import { useSelector } from 'react-redux'
import props from '../../../../../redux/props'
import { Story } from '../../../../../types/Story'
import React, { useState } from 'react'
import Textarea from '../../../Textarea/Textarea'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../../redux/routes'
import useToast from '../../../../hooks/useToast'

const Toggle = ({ state, toggle, save }) => {
  const Button: React.FC<{ onClick: () => {}; children: React.ReactNode }> = ({ onClick, children }) => {
    return (
      <div onClick={onClick} className="cursor-pointer p-2 bg-gray-thirdary rounded-full flex items-center justify-center">
        {children}
      </div>
    )
  }

  const ToggleButton = () => {
    return (
      <Button onClick={() => toggle()}>
        <div className="dfb-Icon h-5 w-fit">
          <svg className="dfb-Symbol dfb-Symbol--dfbIconEdit" viewBox="0 0 24 24" aria-hidden="true">
            <use xlinkHref="#dfb-icon-edit" href="#dfb-icon-edit"></use>
          </svg>
        </div>
      </Button>
    )
  }
  const CancelButton = () => {
    return (
      <Button onClick={() => toggle()}>
        <div className="dfb-Icon h-5 w-fit text-red">
          <svg className="dfb-Symbol dfb-Symbol--dfbIconClose" viewBox="0 0 24 24" aria-hidden="true">
            <use xlinkHref="#dfb-icon-close" href="#dfb-icon-close"></use>
          </svg>
        </div>
      </Button>
    )
  }
  const SaveButton = () => {
    return (
      <Button onClick={save}>
        <div className="dfb-Icon h-5 w-fit">
          <svg className="dfb-Symbol dfb-Symbol--dfbIconSave" viewBox="0 0 24 24" aria-hidden="true">
            <use xlinkHref="#dfb-icon-save" href="#dfb-icon-save"></use>
          </svg>
        </div>
      </Button>
    )
  }

  return (
    <div className="flex flex-row gap-2 items-center">
      {!state && <ToggleButton />}
      {state && <SaveButton />}
      {state && <CancelButton />}
    </div>
  )
}

const StoryText: React.FC<{ story: Story }> = ({ story }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [editStory, setEditStory] = useState<boolean>(false)
  const [text, setText] = useState<string>(story['story'])
  const [editText, setEditText] = useState<string>(story['story'])
  const saveStoryText = functional.use(Routes.SAVE_STORY_TEXT)
  const toast = useToast()

  const toggle = (save = false) => {
    if (editStory) {
      if (save) {
        setText(editText)
      } else {
        setEditText(text)
      }
      setEditStory(false)
    } else {
      setEditStory(true)
    }
  }

  const save = async () => {
    if (text === editText) {
      toggle(true)
    } else {
      const response = await saveStoryText({ uuid: story.uuid, text: editText })
      if (response) {
        toggle(true)
      } else {
        toast(t.admin.list.storyEdit.error.save)
      }
    }
  }

  return (
    <div className="flex flex-col justify-between">
      <div className="flex flex-row gap-3 items-center">
        <div className="font-bold">{t.generic['story']}</div>
        <Toggle state={editStory} toggle={toggle} save={save} />
      </div>
      {!editStory && <div>{text}</div>}
      {editStory && <Textarea value={editText} onChange={setEditText} className="p-1 mt-2 bg-gray-thirdary" />}
    </div>
  )
}

export default StoryText
