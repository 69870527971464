import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { User } from '../../types/User'

type Create = (mail: string, password: string) => Promise<User>

const useCreateUser = (): Create => {
  const createUser = functional.use(Routes.CREATE_USER)

  const create: Create = async (mail, password) => {
    const user: User = await createUser({ mail, password })
    return user
  }

  return create
}

export default useCreateUser
