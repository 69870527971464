import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Story } from '../../../types/Story'
import useToast from '../../hooks/useToast'

const useValidate = () => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])

  const requiredFields = ['firstname', 'lastname', 'mail', 'street', 'city', 'story', 'legalCheck', 'over18Check', 'uploadLegalCheck']

  return (data: Story) => {
    const invalidField = requiredFields.find((field) => !data[field] || (typeof data[field] === 'string' && data[field].trim().length === 0))
    if (!invalidField) return true
    toast(t.index.uploadForm.error.formField(t.generic[invalidField]))
    return false
  }
}

export default useValidate
