import { useEffect, useState } from 'react'
import Input from '../../UI/Input/Input'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import Form from '../../UI/Form/Form'
import Button from '../../UI/Button/Button'
import Textarea from '../../UI/Textarea/Textarea'
import { getNameSuffix, storyMaxCharacters } from '../../../utility'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { Story } from '../../../types/Story'
import DragAndDrop from '../../UI/DragAndDrop/DragAndDrop'
import useFileUpload from '../../hooks/useFileUpload'
import { UPLOAD_TYPES } from '../../UI/DirectFileUpload/DirectFileUpload'
import useAuth from '../../hooks/useOIDCAuth'
import Progress from '../../UI/Progress/Progress'
import Checkbox from '../../UI/Checkbox/Checkbox'
import useToast, { Type } from '../../hooks/useToast'
import useValidate from './useValidate'

const UploadForm = () => {
  const currentLang = useSelector((s) => s[props.CURRENTLANG])
  const t = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState<Story>({})
  const createStory = functional.use(Routes.CREATE_STORY)
  const [file, setFile] = useState<File | null>(null)
  const upload = useFileUpload()
  const [inProgress, setInProgress] = useState(false)
  const [progress, setProgress] = useState(0)
  const auth = useAuth()
  const toast = useToast()
  const validate = useValidate()

  const isLoggedIn = !!auth?.isAuthenticated && !!auth?.user?.profile?.email && !!auth?.user?.access_token
  const isDisabled = !isLoggedIn

  const set = (prop: keyof Story) => (value: any) => {
    setData((prev: Story) => ({ ...prev, [prop]: value }))
  }

  useEffect(() => {
    if (isLoggedIn && (!data.mail || !data.access_token)) {
      setData({ ...data, mail: auth.user.profile.email, access_token: auth.user.access_token })
    }
  }, [auth, setData, data, isLoggedIn])

  const submit = async () => {
    const isValid = validate(data)
    if (!isValid) return
    if (!!file && !!data?.mail && !!data?.access_token) {
      setInProgress(true)
      const uploadedFile = await upload(data.access_token, data.mail, UPLOAD_TYPES.STORY_IMAGE, file, (p) => setProgress(p))
      if (!uploadedFile) {
        setInProgress(false)
        toast(t.index.uploadForm.error.uploadFailed)
        return
      }
      const result = await createStory({ ...data, imageKey: uploadedFile.key, lang: currentLang })
      setInProgress(false)
      if (result === true) {
        setData({ story: '' }) // clear form
        setFile(null) // clear file
        toast(t.index.uploadForm.success, Type.SUCCESS)
      } else if (result === 'limit-reached') {
        toast(t.index.uploadForm.error.limitReached)
      } else {
        toast(t.index.uploadForm.error.processingFailed)
      }
    } else {
      toast(t.index.uploadForm.error.noFile)
    }
  }

  return (
    <div className="h-fit pb-28" id="mitmachen">
      <Container>
        <Form onSubmit={submit}>
          <div className="flex flex-col gap-3 mt-24 pt-24 border-t-2 border-green-primary">
            <div className="font-bold text-xl lg:text-3xl uppercase">{t.index.uploadForm.headline}</div>
            {!isLoggedIn && (
              <div className="flex flex-col gap-3 lg:items-center lg:flex-row lg:justify-between">
                <div className="text-md lg:text-lg">{t.index.uploadForm.loginHint}</div>
                <Button className="lg:py-7 lg:px-10" classNameContent="lg:text-lg" text={t.generic.login} onClick={auth.login} />
              </div>
            )}
          </div>
          <div className="flex flex-col lg:flex-row gap-5 pt-5">
            <div className="w-full flex flex-col gap-5">
              <Input disabled={isDisabled} label={t.generic.firstname} onChange={set('firstname')} value={data.firstname} />
              <Input disabled={isDisabled} label={t.generic.lastname} onChange={set('lastname')} value={data.lastname} />
              <Input disabled={isDisabled} label={t.generic.club} onChange={set('club')} value={data.club} />
              <Input disabled label={t.generic.mail} onChange={() => {}} value={data.mail} type="email" />
            </div>
            <div className="w-full flex flex-col gap-5">
              <Input disabled={isDisabled} label={t.generic.street} onChange={set('street')} value={data.street} />
              <Input disabled={isDisabled} label={t.generic.city} onChange={set('city')} value={data.city} />
              <div className={`border ${isDisabled && 'opacity-50'} border-gray-primary h-full`}>
                <div className={`text-xs px-4 pt-3 ${isDisabled && 'text-gray-placeholder'}`}>
                  {getNameSuffix(t, data.firstname)}
                  {t.index.uploadForm.story.nameSuffix}
                  <span className="text-xs text-gray-placeholder">{t.index.uploadForm.story.maxCharactersHint}</span>
                </div>
                <Textarea
                  disabled={isDisabled}
                  value={data.story}
                  onChange={set('story')}
                  placeholder={t.index.uploadForm.story.placeholder}
                  maxCharacters={storyMaxCharacters}
                  className="!h-fit pt-0"
                  rows={3}
                />
              </div>
            </div>
          </div>
          {!inProgress && (
            <div className="flex flex-col lg:flex-row lg:items-start mt-5 gap-5">
              <div className="flex-1 lg:w-1/2">
                <DragAndDrop disabled={!isLoggedIn} className="h-full w-full overflow-hidden" onFile={setFile}>
                  <div className="flex flex-row items-center  h-full">
                    <div className="flex flex-row items-center gap-3">
                      <div className="rounded-full h-10 aspect-square bg-green-primary flex items-center justify-center">
                        <div className="dfb-Icon">
                          <svg className="dfb-Symbol dfb-Symbol--dfbIconUpload text-white" viewBox="0 0 24 24" aria-hidden="true">
                            <use href="#dfb-icon-upload"></use>
                          </svg>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <div className="text-green-primary font-bold">{t.index.uploadForm.dnd.text}</div>
                        <div className="text-green-primary">{t.index.uploadForm.dnd.hint}</div>
                      </div>
                    </div>
                  </div>
                  {!!file && (
                    <div className="mt-2 truncate w-full text-xs">
                      {t.index.uploadForm.dnd.selectedPrefix}
                      {file.name}
                    </div>
                  )}
                </DragAndDrop>
              </div>
              <div className="flex flex-col gap-5 lg:items-end flex-1 shrink-0">
                <div className="flex flex-col gap-2 w-full">
                  <Checkbox value={data.legalCheck} onChange={set('legalCheck')} disabled={!isLoggedIn}>
                    <div className="text-lightBlack text-xs text-left">
                      {t.index.uploadForm.legal.prefix}
                      <a
                        className="text-green-primary no-underline text-xs"
                        href={t.index.uploadForm.legal.link.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t.index.uploadForm.legal.link.text}
                      </a>
                      {t.index.uploadForm.legal.suffix}
                    </div>
                  </Checkbox>
                  <Checkbox value={data.over18Check} onChange={set('over18Check')} disabled={!isLoggedIn}>
                    <div className="text-lightBlack text-xs text-left">{t.index.uploadForm.legal.over18}</div>
                  </Checkbox>
                  <Checkbox value={data.uploadLegalCheck} onChange={set('uploadLegalCheck')} disabled={!isLoggedIn}>
                    <div className="text-lightBlack text-xs text-left">
                      {t.index.uploadForm.legal.upload.prefix}
                      <a
                        className="text-green-primary no-underline text-xs"
                        href={t.index.uploadForm.legal.upload.link.href}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t.index.uploadForm.legal.upload.link.text}
                      </a>
                      {t.index.uploadForm.legal.upload.suffix}
                    </div>
                  </Checkbox>
                </div>
                <Button
                  disabled={!isLoggedIn}
                  text={t.index.uploadForm.cta}
                  className="lg:py-7 lg:px-10"
                  classNameContent="lg:text-lg"
                  type="submit"
                />
              </div>
            </div>
          )}
          {inProgress && (
            <div className="mt-5">
              <Progress progress={progress} />
            </div>
          )}
        </Form>
      </Container>
    </div>
  )
}

export default UploadForm
