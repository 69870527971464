import { useState } from 'react'
import Items from '../Items/Items'

const Toggle = () => {
  const [open, setOpen] = useState<boolean>(false)

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <>
      <div className="cursor-pointer" onClick={toggle}>
        <div className="dfb-Icon w-10 h-10">
          <svg className="dfb-Symbol dfb-Symbol--dfbIconMenu text-green-primary" viewBox="0 0 24 24" aria-hidden="true">
            <use xlinkHref="#dfb-icon-menu" href="#dfb-icon-menu"></use>
          </svg>
        </div>
      </div>
      <div className={`${open ? 'block' : 'hidden'} fixed h-screen w-full bg-green-primary top-0 left-0 transition-all duration-200`}>
        <div className="mt-10 mx-10">
          <div className="flex justify-end">
            <div className="cursor-pointer" onClick={toggle}>
              <div className="dfb-Icon w-10 h-10">
                <svg className="dfb-Symbol dfb-Symbol--dfbIconClose text-white" viewBox="0 0 24 24" aria-hidden="true">
                  <use xlinkHref="#dfb-icon-close" href="#dfb-icon-close"></use>
                </svg>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Items mobile onClick={toggle} />
          </div>
        </div>
      </div>
    </>
  )
}
export default Toggle
