import { useState } from 'react'
import Button from '../../UI/Button/Button'
import Form from '../../UI/Form/Form'
import Input from '../../UI/Input/Input'
import useCreateUser from '../../hooks/useCreateUser'
import { User } from '../../../types/User'
import useToast from '../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'

type UserCallback = (user: User) => void

type Props = {
  userCallback: UserCallback
}

const Create: React.FC<Props> = ({ userCallback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toast = useToast()
  const createUser = useCreateUser()
  const [data, setData] = useState({ mail: '', password: '' })

  const submit = async () => {
    const user = await createUser(data.mail, data.password)
    if (user) {
      setData({ mail: '', password: '' })
      userCallback(user)
    } else {
      toast(t.admin.users.create.error)
    }
  }

  const set = (prop: keyof typeof data) => (value: any) => {
    setData((prev) => ({ ...prev, [prop]: value }))
  }

  return (
    <div>
      <div className="text-lg mt-5">{t.admin.users.create.headline}</div>
      <Form onSubmit={submit}>
        <div className="flex gap-2 flex-col lg:flex-row items-center">
          <Input className="w-full" label={t.generic.mail} onChange={set('mail')} value={data.mail} type="email" required />
          <Input className="w-full" label={t.generic.password} onChange={set('password')} value={data.password} required type="password" />
          <Button type="submit" text={t.admin.users.create.cta} />
        </div>
        <div className="text-xs text-gray-placeholder">{t.admin.users.create.hint}</div>
      </Form>
    </div>
  )
}

export default Create
