import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { downloadFile, getPrettyDate } from '../../utility'

export enum Type {
  FIRST150 = 'FIRST150',
  EVERYBODY = 'EVERYBODY',
}

const useTriggerExport = () => {
  const triggerExport = functional.use(Routes.TRIGGER_EXPORT)

  const start = async (exportNamePrefix: string, type: Type) => {
    const result = await triggerExport({ type }, null, null, null, null, 'text/plain')
    const date = getPrettyDate(new Date())
    downloadFile(`${exportNamePrefix}-${date}.csv`, result)
  }

  return start
}

export default useTriggerExport
