import { useState } from 'react'
import Input from '../../UI/Input/Input'
import props from '../../../redux/props'
import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import Form from '../../UI/Form/Form'
import Button from '../../UI/Button/Button'
import Textarea from '../../UI/Textarea/Textarea'
import { getNameSuffix, manualEntryCity, manualEntryStreet, storyMaxCharacters } from '../../../utility'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { Story } from '../../../types/Story'
import DragAndDrop from '../../UI/DragAndDrop/DragAndDrop'
import useFileUpload from '../../hooks/useFileUpload'
import { UPLOAD_TYPES } from '../../UI/DirectFileUpload/DirectFileUpload'
import Progress from '../../UI/Progress/Progress'
import useToast, { Type } from '../../hooks/useToast'
import useValidate from '../../Index/UploadForm/useValidate'
import Headline from '../../UI/Headline/Headline'

const ManualEntry = () => {
  const currentLang = useSelector((s) => s[props.CURRENTLANG])
  const t = useSelector((s) => s[props.TRANSLATION])
  const baseData = {
    story: '',
    city: manualEntryCity,
    street: manualEntryStreet,
    legalCheck: true,
    over18Check: true,
    uploadLegalCheck: true,
  }
  const [data, setData] = useState<Story>({ ...baseData })
  const createStory = functional.use(Routes.CREATE_STORY)
  const [file, setFile] = useState<File | null>(null)
  const upload = useFileUpload()
  const [inProgress, setInProgress] = useState(false)
  const [progress, setProgress] = useState(0)
  const toast = useToast()
  const validate = useValidate()

  const isLoggedIn = true
  const isDisabled = false

  const set = (prop: keyof Story) => (value: any) => {
    setData((prev: Story) => ({ ...prev, [prop]: value }))
  }

  const submit = async () => {
    const isValid = validate(data)
    if (!isValid) return
    if (!!file && !!data?.mail) {
      setInProgress(true)
      const uploadedFile = await upload(null, data.mail, UPLOAD_TYPES.STORY_IMAGE, file, (p) => setProgress(p))
      if (!uploadedFile) {
        setInProgress(false)
        toast(t.index.uploadForm.error.uploadFailed)
        return
      }
      const result = await createStory({ ...data, imageKey: uploadedFile.key, lang: currentLang })
      setInProgress(false)
      if (result === true) {
        setData({ ...baseData }) // clear form
        setFile(null) // clear form
        toast(t.index.uploadForm.success, Type.SUCCESS)
      } else if (result === 'limit-reached') {
        toast(t.index.uploadForm.error.limitReached)
      } else {
        toast(t.index.uploadForm.error.processingFailed)
      }
    } else {
      toast(t.index.uploadForm.error.noFile)
    }
  }

  return (
    <Container className="mb-20 pt-10">
      <Headline>{t.admin.manualEntry.headline}</Headline>
      <div>{t.admin.manualEntry.info}</div>

      <Form onSubmit={submit}>
        <div className="flex flex-col lg:flex-row gap-5 pt-5">
          <div className="w-full flex flex-col gap-5">
            <Input disabled={isDisabled} label={t.generic.firstname} onChange={set('firstname')} value={data.firstname} />
            <Input disabled={isDisabled} label={t.generic.lastname} onChange={set('lastname')} value={data.lastname} />
            <Input disabled={isDisabled} label={t.generic.club} onChange={set('club')} value={data.club} />
            <Input label={t.generic.mail} onChange={set('mail')} value={data.mail} type="email" />
          </div>
          <div className="w-full flex flex-col gap-5">
            <div className={`border ${isDisabled && 'opacity-50'} border-gray-primary h-full`}>
              <div className={`text-xs px-4 pt-3 ${isDisabled && 'text-gray-placeholder'}`}>
                {getNameSuffix(t, data.firstname)}
                {t.index.uploadForm.story.nameSuffix}
                <span className="text-xs text-gray-placeholder">{t.index.uploadForm.story.maxCharactersHint}</span>
              </div>
              <Textarea
                disabled={isDisabled}
                value={data.story}
                onChange={set('story')}
                placeholder={t.index.uploadForm.story.placeholder}
                maxCharacters={storyMaxCharacters}
                className="!h-fit pt-0"
                rows={9}
              />
            </div>
          </div>
        </div>
        {!inProgress && (
          <div className="flex flex-col lg:flex-row lg:items-start mt-5 gap-5">
            <div className="flex-1 lg:w-1/2">
              <DragAndDrop disabled={!isLoggedIn} enableAspectRatioCheck={false} className="h-full w-full overflow-hidden" onFile={setFile}>
                <div className="flex flex-row items-center  h-full">
                  <div className="flex flex-row items-center gap-3">
                    <div className="rounded-full h-10 aspect-square bg-green-primary flex items-center justify-center">
                      <div className="dfb-Icon">
                        <svg className="dfb-Symbol dfb-Symbol--dfbIconUpload text-white" viewBox="0 0 24 24" aria-hidden="true">
                          <use href="#dfb-icon-upload"></use>
                        </svg>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="text-green-primary font-bold">{t.index.uploadForm.dnd.text}</div>
                      <div className="text-green-primary">{t.index.uploadForm.dnd.hint}</div>
                    </div>
                  </div>
                </div>
                {!!file && (
                  <div className="mt-2 truncate w-full text-xs">
                    {t.index.uploadForm.dnd.selectedPrefix}
                    {file.name}
                  </div>
                )}
              </DragAndDrop>
            </div>
            <div className="flex flex-col gap-5 lg:items-end flex-1 shrink-0">
              <Button disabled={!isLoggedIn} text={t.index.uploadForm.cta} className="lg:py-7 lg:px-10" classNameContent="lg:text-lg" type="submit" />
            </div>
          </div>
        )}
        {inProgress && (
          <div className="mt-5">
            <Progress progress={progress} />
          </div>
        )}
      </Form>
    </Container>
  )
}

export default ManualEntry
