import styled from 'styled-components'

interface ICoreContent {
  headerTransparent?: boolean
}

export const CoreContent = styled.div<ICoreContent>`
  position: relative;
  background-color: ${({ theme }) => theme.color.bg};
  min-height: 100vh;
  padding-top: ${({ headerTransparent, theme }) => (headerTransparent ? '0px' : theme.headerHeight)};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding-top: ${({ headerTransparent, theme }) => (headerTransparent ? '0px' : theme.headerHeightMobile)};
  }
`
