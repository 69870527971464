import { CoreContent } from './Layout.Styled'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'
import ScrollToTop from './ScrollToTop/ScrollToTop'
import { AuthLevelRequired } from '../../../types/Auth'

type Props = {
  children: React.ReactNode
  className?: string
  headerTransparent?: boolean
  authLevelRequired?: AuthLevelRequired
  noNavigation?: boolean
}

const Layout: React.FC<Props> = ({ children, className, headerTransparent = true, authLevelRequired = [], noNavigation = false }) => {
  return (
    <div className={className}>
      <Secure authLevelRequired={authLevelRequired}>
        <ScrollToTop />
        <Header noNavigation={noNavigation} />
        {/* <CookieConsent> */}
        <CoreContent headerTransparent={headerTransparent}>{children}</CoreContent>
        <Footer />
      </Secure>
    </div>
  )
}

export default Layout
