import ticketBadge from '../../../assets/image/index/hero/ticket-und-badge_white_background.png'
import ticketBadgeEN from '../../../assets/image/index/hero/ticket-und-badge_white_background_en.png'
import signature from '../../../assets/image/signatures/FIDBZ Fussballzeit Handmade 2.png'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { getUUID } from '../../../utility'
import Button from '../../UI/Button/Button'
import Container from '../../UI/Container/Container'

const HeroNew: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const currentLang = useSelector((s) => s[props.CURRENTLANG])

  return (
    <>
      <div className="h-fit bg-green-primary relative">
        <div className="h-full w-full pt-[150px] p-14 flex flex-col items-center justify-end lg:justify-center">
          <img src={signature} alt="Fussballzeit" className="w-auto md:h-20 lg:h-20" />
          <div className="">
            <div className="text-center text-xl md:text-[52.5px] leading-normal lg:leading-[85px] lg:mt-0 text-white font-bold">
              {t.index.hero.subline}
            </div>
            <div className="mt-1 hidden lg:block">
              {t.index.hero.text.map((t: string) => (
                <div key={getUUID()} className="mt-3 lg:mt-0 text-white text-md lg:text-lg text-center leading-7">
                  {t}
                </div>
              ))}
            </div>
            <div className="flex justify-center">
              <Button
                text={t.index.hero.cta}
                href="/#mitmachen"
                inverted
                noBorder
                className="inline-flex mx-auto mt-5 lg:py-6 hover:bg-green-primary group transition-all"
                classNameContent="lg:text-md group-hover:text-white transition-all"
              />
            </div>
          </div>
          <div className="lg:absolute -bottom-7 right-4">
            {currentLang === 'DE' && (
              <img
                src={ticketBadge}
                alt="Trickot mit Ticket und Badge"
                className="-mb-28 mt-5 h-44 sm:h-64 lg:h-auto lg:mb-0 lg:w-[12.5vw] lg:mr-10"
              />
            )}
            {currentLang === 'EN' && (
              <img
                src={ticketBadgeEN}
                alt="Trickot mit Ticket und Badge"
                className="-mb-28 mt-5 h-44 sm:h-64 lg:h-auto lg:mb-0 lg:w-[12.5vw] lg:mr-10"
              />
            )}
          </div>
        </div>
      </div>
      <Container className="block lg:hidden">
        <div className="mt-20 sm:mt-24">
          {t.index.hero.text.map((t: string) => (
            <div key={getUUID()} className="text-center text-lg leading-7">
              {t}
            </div>
          ))}
        </div>
        <div className="flex justify-center">
          <Button text={t.index.hero.cta} href="/#mitmachen" noBorder className="mt-5 lg:py-6" classNameContent="lg:text-md" />
        </div>
      </Container>
    </>
  )
}

export default HeroNew
