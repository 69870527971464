import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'
import { s3Upload } from '../../fetch'
import { UPLOAD_TYPES } from '../UI/DirectFileUpload/DirectFileUpload'

type PresignedUploadResponse = {
  presignedURL: string
  key: string
  url?: string
}

export type OnCallbackFile = {
  url: string
  key: string
  name: string
  mimeType: string
}

type Upload = (
  access_token: string,
  mail: string,
  type: UPLOAD_TYPES,
  file: File,
  progressCallback: (p: number) => void,
) => Promise<OnCallbackFile | false>
type Props = () => Upload

const useFileUpload: Props = () => {
  const getImageUploadPresignedURL = functional.use(Routes.GET_File_UPLOAD_PRESIGNED_URL)

  const upload: Upload = async (access_token, mail, type, file, progressCallback) => {
    const response: PresignedUploadResponse = await getImageUploadPresignedURL({
      access_token,
      mail,
      type,
      meta: { type: file.type, name: file.name, size: file.size },
    })

    if (!response) return false
    const { presignedURL, url, key } = response

    const uploadResult = await s3Upload(presignedURL, file, progressCallback)
    if (!uploadResult) return false
    return { url, key, name: file.name, mimeType: file.type }
  }

  return upload
}

export default useFileUpload
