import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import { getUUID } from '../../../utility'

const Deadline: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="h-fit pt-28">
      <Container>
        <div className="w-full lg:w-10/12">
          {t.index.deadline.headline.map((h: string) => (
            <div className="font-bold text-lg lg:text-2xl uppercase" key={getUUID()}>
              {h}
            </div>
          ))}
          <div className="mt-5 font-bold lg:text-lg">{t.index.deadline.insta.title}</div>
          <ul>
            <li className="list-disc lg:text-lg ml-5">{t.index.deadline.insta.upload.text}</li>
            <li className="list-disc lg:text-lg ml-5">
              {t.index.deadline.insta.share.prefix}
              <span className="lg:text-lg">{t.index.deadline.insta.share.highlight}</span>
              {t.index.deadline.insta.share.prefixDFB}
              <a className="font-bold text-green-primary lg:text-lg" href={t.index.deadline.insta.share.dfbLink} target="_blank" rel="noreferrer">
                {t.index.deadline.insta.share.dfb}
              </a>
              <span className="lg:text-lg">{t.index.deadline.insta.share.prefixFussball}</span>
              <a
                className="font-bold text-green-primary lg:text-lg"
                href={t.index.deadline.insta.share.fussballLink}
                target="_blank"
                rel="noreferrer"
              >
                {t.index.deadline.insta.share.fussball}
              </a>
              <span className="lg:text-lg">{t.index.deadline.insta.share.suffix}</span>
            </li>
            <li className="list-disc lg:text-lg ml-5">{t.index.deadline.insta.luck}</li>
          </ul>
        </div>
      </Container>
    </div>
  )
}

export default Deadline
