import styled from 'styled-components'

export const StyledHeader = styled.div<{ open?: boolean }>`
  width: 100%;
  z-index: 10;
  top: 0;
  height: ${({ theme, open }) => (open ? '100%' : theme.headerHeight)};
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.bg};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: fit-content;
    padding: 15px 0;
  }
`
