import styled, { keyframes } from 'styled-components'

const scrollAnimation = (totalWidth: number, rtl: boolean = false) => keyframes`
  from {
    transform: translateX(${rtl ? -totalWidth : 0}px);
  }
  to {
    transform: translateX(${rtl ? 0 : -totalWidth}px);
  }
`

export const ScrollingContent = styled.div<{ rtl: boolean; totalWidth: number }>`
  display: inline-flex;
  /* in case of 2 sliders based on 500 stories */
  /* animation: ${(props) => scrollAnimation(props.totalWidth, props.rtl)} 1200s linear infinite; */
  /* in case of 3 sliders based on 500 stories */
  animation: ${(props) => scrollAnimation(props.totalWidth, props.rtl)} 800s linear infinite;
  display: flex;
  gap: 1.25rem;
  white-space: nowrap;
  height: 100%;
  scroll-behavior: smooth;
  @media (min-width: ${({ theme }) => theme.breakpoint.md}) {
    &:hover {
      animation-play-state: paused;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    &.active {
      animation-play-state: paused;
    }
  }
`
