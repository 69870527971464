import bg from '../../../assets/image/index/imageSeparator/bg.jpg'

const ImageSeparator = () => {
  return (
    <div className="h-[50vh] lg:h-screen relative">
      <img src={bg} alt="bg" className="w-full h-full object-cover" />
    </div>
  )
}

export default ImageSeparator
