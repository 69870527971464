import { useEffect, useRef, useState } from 'react'
import { Story } from '../../../types/Story'
import Item from './Item'
import { ScrollingContent } from './Slider.Styled'

type Props = {
  stories: Story[]
  rtl?: boolean
  className?: string
}

const Slider: React.FC<Props> = ({ stories, className = '', rtl = false }) => {
  const scrollRef = useRef<HTMLDivElement>()
  const [totalWidth, setTotalWidth] = useState(0)

  useEffect(() => {
    const element = scrollRef.current
    if (element) {
      setTotalWidth(element.scrollWidth - element.clientWidth)
    }
  }, [stories])

  const toggleActive = (uuid: string) => () => {
    const element = scrollRef.current
    if (element) {
      if (uuid) {
        const item = document.getElementById(uuid)
        if (item.classList.contains('active')) {
          item.classList.remove('active')
          element.classList.remove('active')
        } else {
          const active_item = document.querySelector('.slider-item.active')
          if (active_item) active_item.classList.remove('active')
          item.classList.add('active')
          element.classList.add('active')
        }
      } else {
        const active_item = document.querySelector('.slider-item.active')
        if (active_item) active_item.classList.remove('active')
        element.classList.remove('active')
      }
    }
  }

  const getLoadingAttribute = (i: number, rtl: boolean) => {
    if (rtl) {
      return i >= stories.length - 10 ? 'eager' : 'lazy'
    }
    return i <= 10 ? 'eager' : 'lazy'
  }

  return (
    <div className={`h-72 lg:h-96 overflow-x-scroll overflow-y-hidden no-scrollbar ${className}`}>
      <ScrollingContent ref={scrollRef} rtl={rtl} totalWidth={totalWidth}>
        {stories.map((story, i) => (
          <Item key={story.id} loading={getLoadingAttribute(i, rtl)} story={story} onClick={toggleActive} />
        ))}
      </ScrollingContent>
    </div>
  )
}

export default Slider
