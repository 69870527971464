import Layout from '../UI/Layout/Layout'
// import Hero from './Hero/Hero'
import HeroNew from './HeroNew/HeroNew'
import Gallery from './Gallery/Gallery'
import Intro from './Intro/Intro'
import Quote from './Quote/Quote'
import ImageSeparator from './ImageSeparator/ImageSeparator'
import Deadline from './Deadline/Deadline'
import UploadForm from './UploadForm/UploadForm'
import FAQ from './FAQ/FAQ'

const Index = () => {
  return (
    <Layout authLevelRequired={false}>
      {/* <Hero /> */}
      <HeroNew />
      <Gallery />
      <Intro />
      <Quote />
      <Deadline />
      <UploadForm />
      <ImageSeparator />
      <FAQ />
    </Layout>
  )
}

export default Index
