import { useDropzone } from 'react-dropzone'
import { checkAspectRatio, checkFileMimeType, checkFileSize } from './validators'
import useToast from '../../hooks/useToast'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { maxFileSizeInBytes } from '../../../utility'

type Props = {
  onFile: (file: File) => void
  children: React.ReactNode
  className?: string
  disabled?: boolean
  enableAspectRatioCheck?: boolean
}

const DragAndDrop: React.FC<Props> = ({ onFile, children, className = '', disabled = false, enableAspectRatioCheck = true }) => {
  const toast = useToast()
  const t = useSelector((s) => s[props.TRANSLATION])

  const onDrop = async (files: File[]) => {
    if (files.length === 1) {
      const file = files[0]
      if (!checkFileSize(file)) {
        toast(t.index.uploadForm.dnd.error.size)
        return false
      }
      if (!checkFileMimeType(file)) {
        toast(t.index.uploadForm.dnd.error.type)
        return false
      }
      // const dpiCheck = await checkDPI(file)
      // if (!dpiCheck) {
      //   toast(t.index.uploadForm.dnd.error.dpi)
      //   return false
      // }
      if (enableAspectRatioCheck) {
        const aspectRatio = await checkAspectRatio(file)
        if (!aspectRatio) {
          toast(t.index.uploadForm.dnd.error.aspectRatio)
          return false
        }
      }

      onFile(file)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: maxFileSizeInBytes,
    accept: ['image/jpeg', 'image/jpg', 'image/png'],
  })
  return (
    <div className={`${disabled && 'opacity-30 cursor-not-allowed'} outline-none h-fit w-full ${className}`} {...(disabled ? {} : getRootProps())}>
      {!disabled && <input {...getInputProps()} />}

      {children}
    </div>
  )
}

export default DragAndDrop
