import { useEffect, useState } from 'react'
import { Story } from '../../types/Story'
import { s3PublicStoragePrefix, isOffline } from '../../utility'
import offlineGallery from '../../assets/gallery.json'

const useGetGallery = (): Story[] | null => {
  const [stories, setStories] = useState<Story[]>()

  useEffect(() => {
    if (!stories) {
      if (isOffline) {
        setStories(offlineGallery as Story[])
      } else {
        fetch(`${s3PublicStoragePrefix}GALLERY_CACHE/gallery.json`)
          .then((res) => res.json())
          .then((data) => setStories(data))
      }
    }
  }, [stories])

  return stories
}

export default useGetGallery
