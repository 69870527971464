interface IProgress {
  progress: number
  radial?: boolean
  className?: string
}

const Progress: React.FC<IProgress> = ({ progress }) => {
  if (!progress || progress === 0 || progress === 100) return null
  return (
    <div className="h-full w-full">
      <div className="dfb-Progress" role="progressbar" data-progress={progress} id="dfb-progress-example">
        <div className="dfb-Progress-value text-green-primary">{progress}</div>
        <div className="dfb-Progress-progress">
          <span className="dfb-Progress-bar" style={{ width: `${progress}%` }}></span>
        </div>
      </div>
    </div>
  )
}

export default Progress
