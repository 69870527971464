import { useState } from 'react'
import { ImageCrop, Story } from '../../../../types/Story'
import { Mode } from '../types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'
import { getPrettyDateTime } from '../../../../utility'
import GalleryItem from '../../../Index/Gallery/Item'
import Button from '../../Button/Button'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../../redux/routes'
import Protocol from './Protocol'
import StoryText from './StoryText/StoryText'
import Modal from '../../Modal/Modal'
import _ from 'lodash'

type Props = {
  mode: Mode
  initStory: Story
}

const Prop: React.FC<{ story: Story; prop: keyof Story; multiline?: boolean }> = ({ story, prop, multiline = false }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  if (!story[prop]) return <></>
  return (
    <div className={`flex ${multiline ? 'flex-col' : 'flex-row'} justify-between`}>
      <div className="font-bold">{t.generic[prop]}</div>
      <div>{`${prop === 'createdOn' ? getPrettyDateTime(new Date(story[prop])) : story[prop]}`}</div>
    </div>
  )
}

const Item: React.FC<Props> = ({ mode, initStory }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [hidden, setHidden] = useState<boolean>(false)
  const moveToArchive = functional.use(Routes.MOVE_TO_ARCHIVE)
  const moveToPublic = functional.use(Routes.MOVE_TO_PUBLIC)
  const [showProtocol, setShowProtocol] = useState<boolean>(false)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [story, setStory] = useState<Story>(_.cloneDeep(initStory))

  const accept = async () => {
    const response = await moveToPublic({ uuid: story.uuid })
    if (response) setHidden(true)
  }
  const archive = async () => {
    const resonse = await moveToArchive({ uuid: story.uuid })
    if (resonse) setHidden(true)
  }

  const toggleProtocol = () => setShowProtocol((prev) => !prev)
  const toggleConfirmModal = () => setShowConfirmModal((prev) => !prev)
  const saveImageCrop = (imageCrop: ImageCrop) => {
    setStory((prev) => ({ ...prev, imageCrop }))
  }

  if (hidden) return <></>
  return (
    <div className="pt-5 first:pt-0">
      <div className="flex flex-col lg:flex-row gap-2">
        <div className="w-full">
          <Prop prop={'firstname'} story={story} />
          <Prop prop={'lastname'} story={story} />
          <Prop prop={'club'} story={story} />
          <Prop prop={'mail'} story={story} />
          <Prop prop={'street'} story={story} />
          <Prop prop={'city'} story={story} />
          <Prop prop={'createdOn'} story={story} />
          <StoryText story={story} />
        </div>
        <div className="w-full flex items-center justify-center">
          <GalleryItem story={story} editCallback={saveImageCrop} imageOnly={true} className="w-1/2" />
        </div>
      </div>
      <div className="flex flex-col items-center lg:flex-row gap-2 mt-3 justify-end">
        <Button
          inverted
          text={
            <div className="dfb-Icon">
              <svg className="dfb-Symbol dfb-Symbol--dfbIconBook" viewBox="0 0 24 24" aria-hidden="true">
                <use xlinkHref="#dfb-icon-book" href="#dfb-icon-book"></use>
              </svg>
            </div>
          }
          onClick={toggleProtocol}
        />
        {(mode === Mode.MODERATE || mode === Mode.PUBLIC) && <Button danger text={t.admin.list.archive} onClick={archive} />}
        {(mode === Mode.MODERATE || mode === Mode.ARCHIVE) && <Button text={t.admin.list.accept} onClick={toggleConfirmModal} />}
      </div>
      {showProtocol && <Protocol storyUUID={story.uuid} />}
      <Modal confirmButtonText={t.generic.save} onConfirm={accept} show={showConfirmModal} onClose={() => setShowConfirmModal(false)}>
        <div>
          {t.admin.list.acceptModal.text.prefix}
          <span className="font-bold">{story.firstname}</span>
          {t.admin.list.acceptModal.text.suffix}
        </div>
      </Modal>
    </div>
  )
}

export default Item
