import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { useEffect, useState } from 'react'
import Layout from '../../UI/Layout/Layout'
import { Level } from '../../../types/Auth'
import SmallContainer from '../../UI/Container/SmallContainer'
import Input from '../../UI/Input/Input'
import Button from '../../UI/Button/Button'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import Headline from '../../UI/Headline/Headline'
import useToast from '../../hooks/useToast'
import Form from '../../UI/Form/Form'
import { useNavigate } from 'react-router-dom'

const Evaluate = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const hasChangedPassword = functional.use(Routes.HAS_CHANGED_PASSWORD)
  const changePassword = functional.use(Routes.CHANGE_PASSWORD)
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const toast = useToast()
  const [show, setShow] = useState(null)
  const token = useSelector((s) => s[props.TOKEN])
  const navigate = useNavigate()

  useEffect(() => {
    const check = async () => {
      const result = await hasChangedPassword()
      if (!result) {
        setShow(true)
      } else {
        navigate('/admin/moderate')
      }
    }
    if (show === null && !!token) {
      check()
    }
  }, [hasChangedPassword, toast, t, show, token, navigate])

  const submit = async () => {
    if (password !== passwordRepeat) {
      toast(t.admin.evaluate.error.passwordsDontMatch)
    } else {
      const result = await changePassword({ password })
      if (!result) {
        toast(t.admin.evaluate.error.save)
      } else {
        navigate('/admin/moderate')
      }
    }
  }

  return (
    <Layout authLevelRequired={[Level.ADMIN]} noNavigation>
      {show === true && (
        <SmallContainer className="pt-52">
          <Headline className="text-center">{t.admin.evaluate.headline}</Headline>
          <div className="mt-5 max-w-xs mx-auto">
            <Form onSubmit={submit}>
              <div className="flex gap-2 flex-col">
                <Input label={t.generic.password} onChange={setPassword} value={password} required={true} type="password" />
                <Input label={t.generic.passwordRepeat} onChange={setPasswordRepeat} value={passwordRepeat} required={true} type="password" />
                <Button type="submit" text={t.admin.evaluate.cta} />
              </div>
            </Form>
          </div>
        </SmallContainer>
      )}
    </Layout>
  )
}

export default Evaluate
