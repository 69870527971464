import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import props from '../../../../../../../redux/props'
import { Lang, LangKey } from '../../../../../../../redux/defaultState'
import { setLocal } from '../../../../../../../redux/action/local'
import cookie, { LANG_COOKIE } from '../../../../../helper/cookie'
import deIcon from '../../../../../../../assets/image/lang/de.png'
import enIcon from '../../../../../../../assets/image/lang/en.png'
import detectBrowserLanguage from 'detect-browser-language'

type Props = {
  selectedCallback?: () => void
}

const LangSwitcher: React.FC<Props> = ({ selectedCallback }) => {
  const languages: Lang[] = useSelector((s) => s[props.LANGUAGES])
  const currentLang = useSelector((s) => s[props.CURRENTLANG])
  const dispatch = useDispatch()
  const [evaluated, setEvaluated] = useState(false)

  const iconMapping = {
    DE: deIcon,
    EN: enIcon,
  }

  const switchLang = useCallback(
    (lang: LangKey) => () => {
      const newLang = languages.find((l) => l.langKey === lang)
      document.getElementsByTagName('html')[0].setAttribute('lang', newLang.langKey.toLowerCase())
      dispatch(setLocal(props.TRANSLATION, newLang.translation))
      dispatch(setLocal(props.CURRENTLANG, newLang.langKey))
      cookie.set(LANG_COOKIE, newLang.langKey)
      setEvaluated(true)
      if (selectedCallback) {
        selectedCallback()
      }
    },
    [dispatch, languages, selectedCallback],
  )

  useEffect(() => {
    if (!!currentLang) {
      const lang = cookie.get(LANG_COOKIE)
      if (!!lang && lang !== currentLang) {
        switchLang(lang)()
      } else if (!lang) {
        const isBrowserDE = detectBrowserLanguage().toLowerCase().includes('de')
        if (isBrowserDE) {
          switchLang('DE')()
        } else {
          switchLang('EN')()
        }
      } else {
        setEvaluated(true)
      }
    }
  }, [currentLang, switchLang])

  return (
    <div className="flex flex-row gap-3 mt-3 lg:mt-0">
      {languages.map((lang: Lang) => (
        <div
          className={`cursor-pointer flex flex-row items-center gap-1 text-white lg:text-black ${
            lang.langKey === currentLang && !!evaluated ? 'font-bold' : 'opacity-75 lg:opacity-50'
          }`}
          key={lang.langKey}
          onClick={switchLang(lang.langKey)}
        >
          <img src={iconMapping[lang.langKey]} alt={lang.word} className="h-6 aspect-square rounded-full" />
          {lang.langKey}
        </div>
      ))}
    </div>
  )
}

export default LangSwitcher
