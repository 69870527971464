import { getDateInputFormat, maxInputLength } from '../../../utility'

export interface BaseInput extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  id?: string
  type?: string
  value: any
  label?: string
  placeholder?: string
  onChange: (value: any) => void
  required?: boolean
  className?: string
  contentClassName?: string
  autoComplete?: string
  returnRawChangeEvent?: boolean
  disabled?: boolean
  hiddenDisabled?: boolean
  textAlign?: string
  useRef?: any
}

const Input: React.FC<BaseInput> = ({
  id,
  type = 'text',
  value,
  label,
  placeholder,
  onChange,
  required = false,
  className = '',
  contentClassName = '',
  autoComplete = 'on',
  returnRawChangeEvent = false,
  disabled = false,
  hiddenDisabled = false,
  textAlign = null,
  useRef,
}) => {
  const fallbackID = Math.random().toString(36).substring(7)
  const prepValue = () => {
    if (!value) return ''
    if (type === 'date') {
      if (typeof value === 'object') {
        return getDateInputFormat(value)
      } else if (value.includes('T')) {
        return getDateInputFormat(new Date(value))
      }
      return value
    } else {
      return value
    }
  }

  const getID = () => {
    if (id) return id
    return fallbackID
  }

  return (
    <div className={`dfb-Input dfb-Input--plain ${disabled && 'is-disabled'} ${className}`}>
      <div className="dfb-Input-field w-full">
        <input
          id={getID()}
          ref={useRef}
          disabled={disabled || hiddenDisabled}
          required={required}
          type={type}
          value={prepValue()}
          maxLength={maxInputLength}
          onChange={(e) => onChange(returnRawChangeEvent ? e : e.target.value)}
          placeholder={placeholder || label}
          className={`dfb-Input-input ${contentClassName} ${!!textAlign ? ` text-${textAlign}` : ''} ${
            hiddenDisabled ? 'disabled:bg-white disabled:border-none disabled:text-opacity-100' : ''
          }`}
          autoComplete={autoComplete}
        />
      </div>
    </div>
  )
}

export default Input
