import { useSelector } from 'react-redux'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'
import { getUUID } from '../../../utility'
import tickets from '../../../assets/image/index/intro/tickets.png'
import Video from './Video'

const Intro: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="h-fit py-28 lg:pb-0 bg-white" id="fussballzeit">
      <Container>
        <div className="w-full lg:w-9/12">
          {t.index.intro.headline.map((h: string) => (
            <div className="font-bold text-lg lg:text-2xl lg:leading-[3rem] uppercase" key={getUUID()}>
              {h}
            </div>
          ))}
          <div className="mt-10 flex flex-col gap-10">
            <div className="lg:text-lg">{t.index.intro.text}</div>
            <div className="lg:text-lg">
              <span className="lg:text-lg font-bold">{t.index.intro.prefix}</span>
              {t.index.intro.suffix}
            </div>
          </div>
        </div>
        <div className="mt-16">
          <Video />
        </div>
        <div className="my-16 flex flex-col md:flex-row gap-10">
          <div className="flex flex-col gap-7 w-full md:w-6/12">
            <div className="font-bold text-lg lg:text-2xl leading-[3rem] uppercase mt-14">{t.index.intro.contest.headline}</div>
            <div className="lg:text-lg">{t.index.intro.contest.special.suffix}</div>
          </div>
          <div className="w-full md:w-6/12 relative flex justify-center items-center lg:items-start">
            <img src={tickets} alt="Tickets" className="w-3/5 md:w-2/3 h-auto lg:w-4/5" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Intro
