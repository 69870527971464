import { useSelector } from 'react-redux'
import { ImageCrop, Story } from '../../../types/Story'
import props from '../../../redux/props'
import signature1 from '../../../assets/image/signatures/FIDBZ Fussballzeit Handmade 1.png'
import signature2 from '../../../assets/image/signatures/FIDBZ Fussballzeit Handmade 2.png'
import signature3 from '../../../assets/image/signatures/FIDBZ Fussballzeit Handmade 3.png'
import { getNameSuffix, s3StoryImageCompressedFolder, s3StoryImageFolder } from '../../../utility'
import Editor from './Editor/Editor'
import Image from './Image/Image'
import { useState } from 'react'

type Props = {
  story: Story
  className?: string
  imageOnly?: boolean
  onClick?: (uuid: string) => () => void
  editCallback?: (imageCrop: ImageCrop) => void
  loading?: 'eager' | 'lazy'
}

const randomSignature = () => {
  const images = [signature1, signature2, signature3]
  return images[Math.floor(Math.random() * images.length)]
}

const Item: React.FC<Props> = ({ story, className = '', imageOnly = false, onClick = null, editCallback = null, loading = 'eager' }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [signature] = useState(randomSignature())

  const getURL = () => {
    // cloudfront image url composition
    // return !!story.isCompressed
    //   ? s3PublicStoragePrefixCloudfront + story.imageKey.replace(s3StoryImageFolder, s3StoryImageCompressedFolder)
    //   : s3PublicStoragePrefixCloudfront + story.imageKey

    // s3 image url composition
    return !!story.isCompressed ? story.imageURL.replace(s3StoryImageFolder, s3StoryImageCompressedFolder) : story.imageURL
  }

  return (
    <div
      id={story.id}
      className={`slider-item aspect-4/5 shrink-0 relative rounded-2xl overflow-hidden h-full group cursor-pointer ${className}`}
      onClick={onClick ? onClick(story.id) : null}
      onBlur={onClick ? onClick(null) : null}
      tabIndex={-1}
    >
      <Image src={getURL()} loading={loading} imageCrop={story.imageCrop} />
      {editCallback && <Editor story={story} callback={editCallback} />}
      {!imageOnly && (
        <div
          className={`absolute bottom-0 left-0 w-full h-full bg-green-primary max-md:group-[.active]:h-full 
          md:group-hover:h-full ${'bg-opacity-0'} transition-all duration-300 max-md:group-[.active]:bg-opacity-80 
          md:group-hover:bg-opacity-80 p-4 flex flex-col gap-1 justify-end max-md:group-[.active]:justify-center md:group-hover:justify-center
          bg-gradient-to-tr from-[rgba(0,0,0,0.25)] to-transparent max-md:group-[.active]:from-transparent md:group-hover:from-transparent`}
        >
          <div className="text-sm px-2 truncate whitespace-nowrap uppercase text-white font-bold">{getNameSuffix(t, story.firstname)}</div>
          <img src={signature} className="max-w-full" alt="Fussballzeit" />
          <div
            className={`h-0 overflow-hidden max-md:group-[.active]:h-auto md:group-hover:h-auto text-white break-words whitespace-pre-wrap leading-5 mt-2`}
          >
            {story.story}
          </div>
        </div>
      )}
    </div>
  )
}

export default Item
