enum Routes {
  GET_File_UPLOAD_PRESIGNED_URL = 'GET_File_UPLOAD_PRESIGNED_URL',
  CREATE_STORY = 'CREATE_STORY',
  LIST_STORIES = 'LIST_STORIES',
  MOVE_TO_ARCHIVE = 'MOVE_TO_ARCHIVE',
  MOVE_TO_PUBLIC = 'MOVE_TO_PUBLIC',
  TRIGGER_EXPORT = 'TRIGGER_EXPORT',
  GET_MOVE_PROTOCOL = 'GET_MOVE_PROTOCOL',
  LIST_USERS = 'LIST_USERS',
  CREATE_USER = 'CREATE_USER',
  SAVE_STORY_TEXT = 'SAVE_STORY_TEXT',
  HAS_CHANGED_PASSWORD = 'HAS_CHANGED_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  SAVE_STORY_IMAGE_CROP = 'SAVE_STORY_IMAGE_CROP',
}

export default Routes
