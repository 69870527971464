import props from '../../../../redux/props'
import { useSelector } from 'react-redux'
import { getUUID } from '../../../../utility'
import twitter from '../../../../assets/image/twitter.png'

type Item = {
  title: string
  href?: string
}

const Footer = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  const baseClasses = 'text-sm text-black no-underline'

  return (
    <div className="bg-gray-thirdary p-7">
      <div className="flex flex-col lg:flex-row gap-2 justify-between lg:items-center">
        {t.footer.items.map((i: Item) => {
          if (!i.href)
            return (
              <div className={baseClasses} key={getUUID()}>
                {i.title}
              </div>
            )
          return (
            <a href={i.href} key={getUUID()} className={baseClasses} target="_blank" rel="noreferrer">
              {i.title}
            </a>
          )
        })}
        <div className={baseClasses}>
          <div id="trigger_user_centrics" className="cursor-pointer">
            {t.footer.cookieConsent.title}
          </div>
        </div>
        <div className="flex flex-row gap-5 items-center mt-3 lg:mt-0">
          <a href={t.footer.social.instagram} target="_blank" rel="noreferrer" className={`flex items-center ${baseClasses}`}>
            <div className="dfb-Icon">
              <svg className="dfb-Symbol dfb-Symbol--dfbIconInstagram" viewBox="0 0 24 24" aria-hidden="true">
                <use xlinkHref="#dfb-icon-instagram" href="#dfb-icon-instagram"></use>
              </svg>
            </div>
          </a>
          <a href={t.footer.social.linkedin} target="_blank" rel="noreferrer" className={`flex items-center ${baseClasses}`}>
            <div className="dfb-Icon">
              <svg className="dfb-Symbol dfb-Symbol--dfbIconLinkedIn" viewBox="0 0 24 24" aria-hidden="true">
                <use xlinkHref="#dfb-icon-linkedin" href="#dfb-icon-linkedin"></use>
              </svg>
            </div>
          </a>
          <a href={t.footer.social.twitter} target="_blank" rel="noreferrer" className={baseClasses}>
            <img src={twitter} className="fill-black h-[18px]" alt="X" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
