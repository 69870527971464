interface IButton {
  text?: string | React.ReactNode
  type?: string
  href?: string
  onClick?: () => void
  inverted?: boolean
  noBorder?: boolean
  danger?: boolean
  className?: string
  classNameContent?: string
  disabled?: boolean
}

enum Type {
  DEFAULT = 'default',
  SUBMIT = 'submit',
}

const Button: React.FC<IButton> = ({
  text,
  onClick,
  href,
  inverted = false,
  noBorder = false,
  danger = false,
  type = Type.DEFAULT,
  className = '',
  classNameContent = '',
  disabled = false,
}) => {
  if (!!href) {
    return (
      <a
        className={`dfb-Button ${danger && 'is-invalid'} ${inverted ? 'dfb-Button--secondary bg-white' : 'bg-green-primary'} w-fit ${
          noBorder && 'border-none'
        } ${className}`}
        href={href}
      >
        <span className={`dfb-Button-label ${classNameContent}`}>{text}</span>
      </a>
    )
  }
  if (type === Type.SUBMIT) {
    return (
      <button
        className={`dfb-Button ${danger && 'is-invalid'} ${inverted ? 'dfb-Button--secondary bg-white' : 'bg-green-primary'} w-fit ${
          noBorder && 'border-none'
        } ${className}`}
        type="submit"
        disabled={disabled}
      >
        <span className={`dfb-Button-label ${classNameContent}`}>{text}</span>
      </button>
    )
  }
  return (
    <button
      className={`dfb-Button ${danger && 'is-invalid'} ${inverted ? 'dfb-Button--secondary bg-white' : 'bg-green-primary'} w-fit ${
        noBorder && 'border-none'
      } ${className}`}
      type="button"
      onClick={onClick}
      disabled={disabled}
    >
      <span className={`dfb-Button-label ${classNameContent}`}>{text}</span>
    </button>
  )
}

export default Button
