import { v4 as uuidv4 } from 'uuid'
import currencyFormatter from 'currency-formatter'
import prettyBytes from 'pretty-bytes'
import FileDownload from 'js-file-download'
import { AuthProviderProps } from 'react-oidc-context'

export const backendDomain = process.env.NODE_ENV === 'production' ? 'https://fussballzeit-backend.dfb.de' : 'http://localhost:8100'

export const isOffline = !!process.env.NODE_ENV && process.env.NODE_ENV === 'development'

export const s3PublicStoragePrefix = 'https://s3.eu-central-1.amazonaws.com/creatal.de.dfb-fussballzeit.storage/'
export const s3PublicStoragePrefixCloudfront = 'https://dfb-storage.creatal.de/'

export const storyMaxCharacters = 150
export const maxInputLength = 100
// export const totalStoriesInGallery = 200
export const totalStoriesInGallery = 500

export const maxFileSizeInBytes = 5000000
export const allowedFileMimeTypes = ['image/jpg', 'image/jpeg', 'image/png']
export const minDPIAmount = 100

export const s3StoryImageFolder = 'STORY_IMAGE'
export const s3StoryImageCompressedFolder = 'STORY_IMAGE_COMPRESSED'

export const manualEntryStreet = 'Unbekannt'
export const manualEntryCity = 'Unbekannt'

export const isLive = window.location.host === 'fussballzeit.dfb.de'

export const userCentricsSettingsID = 'XG6QFZdGcdEtBq'
export const adobeTrackingURL = isLive
  ? 'https://assets.adobedtm.com/97c422f768f3/c6e0a424ac7e/launch-3d9843e0ecec.min.js'
  : 'https://assets.adobedtm.com/97c422f768f3/62a7d15c8859/launch-e39d91db8667-staging.min.js'

export const oidcAuthority = isLive ? 'https://oidc.dfb.de/auth/realms/AK' : 'https://oidc.refz-dfb-akademie.de/auth/realms/AK'
export const oidcClientID = isLive ? 'dfb-fussballzeit' : 'dfb-fussballzeit'
export const oidcClientSecret = isLive ? 'A7RHrqgcckdI7f0v6pcyR4gTom8y6u6d' : 'pwEExoHtg8JgxvO7mmOl0ezLiT2ZA82T'
export const oidcRedirectURI = `${window.location.origin}/#mitmachen`
export const oidcConfig: AuthProviderProps = {
  authority: oidcAuthority,
  client_id: oidcClientID,
  client_secret: oidcClientSecret,
  redirect_uri: oidcRedirectURI,
  automaticSilentRenew: true,
  display: 'page',
  scope: 'openid profile email',
  response_type: 'code',
  loadUserInfo: true,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname)
  },
}

export const getUUID = () => uuidv4()

export const getDateInputFormat = (date: Date) => {
  const fill = (number: number) => {
    const numberAsString = `${number}`
    return numberAsString.length === 1 ? `0${number}` : number
  }

  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

export const frontendDomain = process.env.NODE_ENV === 'production' ? 'https://fussballzeit.dfb.de' : 'http://localhost:3000'

export const formatFileSize = (sizeInBytes, locale = 'de') => {
  return prettyBytes(sizeInBytes, { locale })
}

export const triggerClientSideDownload = (data, filename) => FileDownload(data, filename)

export const downloadFile = (name: string, blobData: Blob) => {
  var link = document.createElement('a')
  link.href = window.URL.createObjectURL(blobData)
  link.download = name
  link.click()
}

export const downloadFileFromURL = (name: string, url: string) => {
  var link = document.createElement('a')
  link.href = url
  link.download = name
  link.dispatchEvent(new MouseEvent('click'))
}

export const formatEUR = (amount) => {
  return currencyFormatter.format(amount, {
    locale: 'de-DE',
    symbol: '€',
    decimal: ',',
    thousand: '.',
    format: '%v %s',
  })
}

export const formatNumber = (value) => {
  return new Intl.NumberFormat('de-DE').format(value)
}

export const validateMail = (mail) => {
  const mailRegex = new RegExp('^\\S+@\\S+\\.\\S+$')
  return mailRegex.test(mail)
}

export const cookieLiveTimeInMillis = 1000 * 60 * 30

const fill = (number: Number) => {
  const numberAsString = `${number}`
  return numberAsString.length === 1 ? `0${numberAsString}` : numberAsString
}

export const getPrettyTime = (date: Date) => {
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${hour}:${minute}`
}
export const getPrettyDateTime = (date: Date) => {
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  const minute = fill(date.getMinutes())
  const hour = fill(date.getHours())
  return `${day}.${month}.${year} ${hour}:${minute}`
}
export const getPrettyDate = (date: Date) => {
  const day = fill(date.getDate())
  const month = fill(date.getMonth() + 1)
  const year = date.getFullYear()
  return `${day}.${month}.${year}`
}

export const getNameSuffix = (t: any, firstname: string) => {
  const useSpecialIndexOnNamesWithEnding = ['s', 'z', 'x', 'S', 'Z', 'X']
  if (!firstname) return `${t.index.uploadForm.story.placeholderName}${t.generic.firstnameSuffix}`
  if (!!useSpecialIndexOnNamesWithEnding.includes(firstname.trim()[firstname.trim().length - 1]))
    return `${firstname.trim()}${t.generic.firstnameSpecialSuffix}`
  return `${firstname.trim()}${t.generic.firstnameSuffix}`
}
