import { User } from 'oidc-client-ts'
import { useAuth as useOIDCAuth } from 'react-oidc-context'

type Auth = { isLoading: boolean; isAuthenticated?: boolean; user?: User; login?: () => void; logout?: () => void }

const useAuth = (): Auth => {
  const { isLoading, isAuthenticated, error, user, signinRedirect, signoutSilent } = useOIDCAuth()

  if (isLoading) {
    return { isLoading: true, login: signinRedirect }
  }
  if (error) {
    return { isLoading: false, isAuthenticated: false, login: signinRedirect }
  }
  if (isAuthenticated) {
    return {
      isLoading: false,
      isAuthenticated: true,
      user,
      login: signinRedirect,
      logout: () => signoutSilent({ post_logout_redirect_uri: window.location.origin }),
    }
  } else {
    return { isLoading: false, isAuthenticated: false, login: signinRedirect }
  }
}

export default useAuth
