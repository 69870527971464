import { useState } from 'react'
import thumbnail from '../../../assets/image/index/video/thumbnail.jpg'
import thumbnailEN from '../../../assets/image/index/video/thumbnail_en.jpg'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'

const Video = () => {
  const [show, setShow] = useState<boolean>(false)
  const currentLang = useSelector((s) => s[props.CURRENTLANG])

  return (
    <div className="aspect-video">
      {!show && (
        <>
          {currentLang === 'DE' && (
            <img onClick={() => setShow(true)} className="h-full w-full object-cover cursor-pointer" src={thumbnail} alt="FUSSBALLZEIT Trailer" />
          )}
          {currentLang === 'EN' && (
            <img onClick={() => setShow(true)} className="h-full w-full object-cover cursor-pointer" src={thumbnailEN} alt="FUSSBALLZEIT Trailer" />
          )}
        </>
      )}
      {show && (
        <video width="100%" poster={currentLang === 'EN' ? thumbnailEN : thumbnail} controls autoPlay>
          {currentLang === 'DE' && (
            <source
              src="https://s3.eu-central-1.amazonaws.com/creatal.de.dfb-fussballzeit.storage/Aufsager-Fussballzeit-BNE-Botschafterinnen.mp4"
              type="video/mp4"
            />
          )}
          {currentLang === 'EN' && (
            <source
              src="https://s3.eu-central-1.amazonaws.com/creatal.de.dfb-fussballzeit.storage/240409_FussballzeitAufsager_EnglSubs.mp4"
              type="video/mp4"
            />
          )}
        </video>
      )}
    </div>
  )
}

export default Video
