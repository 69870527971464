import React from 'react'
import ReactDOM from 'react-dom'
import './global.scss'
import App from './App'
import { Provider } from 'react-redux'
import { AuthProvider } from 'react-oidc-context'
import store from './redux/configureStore'
import Tracking from './components/UI/Layout/Tracking/Tracking'
import { oidcConfig } from './utility'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider {...oidcConfig}>
        <Tracking />
        <App />
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
