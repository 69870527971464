import React, { useState } from 'react'
import ReactCrop, { Crop, PercentCrop, PixelCrop, makeAspectCrop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './CanvasPreview'

type Props = {
  src: string
  initCrop?: PercentCrop
  onChange: (c: PercentCrop) => void
}

const ImageCropper: React.FC<Props> = ({ src, initCrop = null, onChange }) => {
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const imgRef = React.useRef<HTMLImageElement>(null)
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null)

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop)
      }
    },
    100,
    [completedCrop],
  )

  const onImageLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget

    const crop = {
      unit: '%',
      width: 100,
      height: 100,
      ...initCrop,
    }

    setCrop(makeAspectCrop(crop, 4 / 5, width, height))
  }

  const update = (c: PixelCrop, cP: PercentCrop) => {
    setCompletedCrop(c)
    onChange(cP)
  }

  return (
    <div className="flex flex-col md:flex-row gap-5">
      <div className="flex-1 flex items-center justify-center">
        <ReactCrop crop={crop} aspect={4 / 5} onChange={setCrop} keepSelection onComplete={(c, pC) => update(c, pC)}>
          <img ref={imgRef} src={src} className="max-w-full max-h-full" onLoad={onImageLoad} alt="" />
        </ReactCrop>
      </div>
      <div className="flex-1 flex items-center justify-center">
        {completedCrop && (
          <canvas
            ref={previewCanvasRef}
            className="aspect-4/5 w-full rounded-2xl overflow-hidden object-cover"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        )}
      </div>
    </div>
  )
}

export default ImageCropper
