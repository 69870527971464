import React from 'react'

type Props = {
  value?: boolean
  children: string | React.ReactNode
  onChange: (value: boolean) => void
  disabled?: boolean
  required?: boolean
  className?: string
}

const Checkbox: React.FC<Props> = ({ value = false, children, onChange, disabled, required, className = '' }) => (
  <div className={`dfb-Checkbox ${required && 'is-required'} ${className}`}>
    <label className="dfb-Checkbox-field flex flex-row items-start">
      <input
        className="dfb-Checkbox-input !block !appearance-auto w-1 h-1 overflow-hidden !absolute bottom-0 left-0 !z-0"
        type="checkbox"
        checked={value}
        name="checkbox"
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
        required={required}
      />
      <span className="dfb-Checkbox-box" aria-hidden="true"></span>
      <span className={`dfb-Checkbox-label ${disabled && 'opacity-30'}`}>{children}</span>
    </label>
  </div>
)

export default Checkbox
