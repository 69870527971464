import { useSelector } from 'react-redux'
import Modal from '../../../UI/Modal/Modal'
import props from '../../../../redux/props'
import { useState } from 'react'
import ImageCropper from '../../../UI/ImageCropper/ImageCropper'
import { PercentCrop } from 'react-image-crop'
import Routes from '../../../../redux/routes'
import { functional } from '@think-internet/zeus-frontend-package'
import { ImageCrop, Story } from '../../../../types/Story'
import useToast from '../../../hooks/useToast'

const Editor: React.FC<{ story: Story; callback?: (imageCrop: ImageCrop) => void }> = ({ story, callback }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [showGallery, setShowGallery] = useState<boolean>(false)
  const [crop, setCrop] = useState<PercentCrop>(story.imageCrop || null)
  const saveStoryImageCrop = functional.use(Routes.SAVE_STORY_IMAGE_CROP)
  const toast = useToast()

  const toggle = () => {
    setShowGallery(!showGallery)
  }

  const save = async () => {
    if (!!crop) {
      const response = await saveStoryImageCrop({ uuid: story.uuid, imageCrop: crop })
      if (response) {
        toggle()
        callback(crop)
      } else {
        toast(t.admin.list.storyEdit.error.save)
      }
    }
  }

  if (!callback) return null
  return (
    <>
      <div className="absolute top-0 left-0 h-full w-full cursor-pointer" onClick={toggle}></div>
      <Modal large confirmButtonText={t.generic.save} onConfirm={save} show={showGallery} onClose={() => setShowGallery(false)}>
        <ImageCropper src={story.imageURL} initCrop={story.imageCrop} onChange={setCrop} />
      </Modal>
    </>
  )
}

export default Editor
