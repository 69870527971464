import { useSelector } from 'react-redux'
import Accordion from '../../UI/Accordion/Accordion'
import Container from '../../UI/Container/Container'
import props from '../../../redux/props'

const FAQ = () => {
  const t = useSelector((s) => s[props.TRANSLATION])

  return (
    <div className="h-fit pb-28 pt-14 mt-14 bg-white" id="faq">
      <Container>
        <div className="text-xl lg:text-3xl font-bold">{t.index.faq.title}</div>
        <Accordion className="mt-5" items={t.index.faq.items} />
      </Container>
    </div>
  )
}

export default FAQ
