import { useRef, useState } from 'react'
import { ImageCrop } from '../../../../types/Story'
import styled from 'styled-components'

type Adjustment = {
  x: number
  y: number
  height: number
  width: number
}

const AdjustedImage = styled.img<{ adjustment: Adjustment }>`
  pointer-events: none;
  max-width: auto !important;
  position: absolute;
  width: auto;
  ${({ adjustment }) =>
    adjustment
      ? `
      height: ${adjustment.height}px;
      top: -${adjustment.y}px;
      left: -${adjustment.x}px;
  `
      : 'visibility: hidden;'}
`

const Image: React.FC<{ src: string; imageCrop: ImageCrop; loading?: 'eager' | 'lazy' }> = ({ src, imageCrop, loading = 'eager' }) => {
  const imgRef = useRef<HTMLImageElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const [adjustment, setAdjustment] = useState<Adjustment>()

  const adjustImage = () => {
    if (imageCrop && containerRef.current && imgRef.current) {
      const { x, y, height, width } = imageCrop

      const heightMultiplier = 100 / height
      const containerHeight = containerRef.current.clientHeight
      const newImageHeight = containerHeight * heightMultiplier

      const widthMultiplier = 100 / width
      const containerWidth = containerRef.current.clientWidth
      const newImageWidth = containerWidth * widthMultiplier

      const newX = (newImageWidth / 100) * x
      const newY = (newImageHeight / 100) * y

      setAdjustment({
        x: newX,
        y: newY,
        width: newImageWidth,
        height: newImageHeight,
      })
    }
  }

  if (imageCrop) {
    return (
      <div className="h-full w-full relative" ref={containerRef}>
        <AdjustedImage
          src={src}
          adjustment={adjustment}
          ref={imgRef}
          alt=""
          loading={loading}
          onLoad={adjustImage}
          className="max-w-none max-h-none"
        />
      </div>
    )
  }
  return <img src={src} alt="" className="pointer-events-none h-full w-full object-cover" loading={loading} />
}

export default Image
