//
// ONLY EDIT THIS FILE IN /frontend/src/
//

import { storyMaxCharacters } from '../utility'

export const translation = {
  projectName: 'DFB Fussballzeit',

  header: {
    search: {
      placeholder: 'Ort, Künstler ...',
      cta: 'suchen',
    },
    navigation: {
      default: [
        {
          name: 'Fussballzeit',
          link: '/#fussballzeit',
        },
        {
          name: 'Mitmachen',
          link: '/#mitmachen',
        },
        {
          name: 'FAQ',
          link: '/#faq',
        },
      ],
      ADMIN: [
        {
          name: 'Moderation',
          link: '/admin/moderate',
        },
        {
          name: 'Öffentlich',
          link: '/admin/public',
        },
        {
          name: 'Archiv',
          link: '/admin/archive',
        },
        {
          name: 'Export',
          link: '/admin/export',
        },
        {
          name: 'Benutzer',
          link: '/admin/users',
        },
        {
          name: 'Eintrag erstellen',
          link: '/admin/manual-entry',
        },
      ],
      isLoggedIn: {
        name: 'Abmelden',
      },
    },
  },

  index: {
    hero: {
      subline: 'IST DIE BESTE ZEIT .',
      text: [
        'Die UEFA EURO 2024™ steht vor der Tür und ganz Deutschland fiebert mit!',
        'Du auch? Dann zeig uns, wie Deine beste FUSSBALLZEIT aussieht!',
      ],
      cta: 'Jetzt mitmachen',
    },
    gallery: {
      hint: '*Für einen einheitlichen textlichen Aufbau aller FUSSBALLZEIT-Momente behalten wir uns kleine Anpassungen vor.',
    },
    intro: {
      headline: ['Fussballzeit ist deine beste Zeit?', 'Mach mit und Räum ab!'],
      text: 'Du gehst regelmäßig mit Deinen Freund*innen ins Stadion? Du spielst bei Wind und Wetter mit Deinen Kindern im Garten Fußball? Du bist jeden ersten Sonntag im Monat mit Deinen Stammtischkollegen in Eurer Lieblingskneipe? Dein Onkel hat die größte Trikotsammlung, die Du je gesehen hast?',
      prefix: 'Perfekt! Denn wir suchen genau solche Storys.',
      suffix:
        ' Geschichten, die FUSSBALLZEIT zu der besten Zeit machen. Lade dafür einfach Dein Foto hoch, das Deinen FUSSBALLZEIT-Moment am besten zusammenfasst, ergänze einen kurzen Beschreibungstext und erwecke unsere deutschlandweite Kampagne gemeinsam mit anderen Fußballbegeisterten zum Leben.',
      contest: {
        headline: 'Das kannst du gewinnen',
        fast: {
          prefix: 'Schnellverlosung: ',
          suffix:
            'Wenn Du Deine FUSSBALLZEIT hochlädst und Dein Beitrag zu den 150 ersten Einsendungen gehört, gewinnst Du eines von 150 DFB-Trikots (in Einheitsgröße).*',
        },
        special: {
          prefix: 'Sonderverlosung: ',
          suffix:
            'Unter allen FUSSBALLZEITEN, die bis zum 31.05.2024 eingereicht werden, verlosen wir zudem 10 x 2 Tickets für die Heim-EM im Sommer.',
          hint: '* Nur solange der Vorrat reicht.',
        },
      },
    },
    quote: {
      text: 'Teile Deine persönliche Geschichte mit uns und werde Teil der deutschlandweiten Kampagne „FUSSBALLZEIT IST DIE BESTE ZEIT“.',
      author: 'GEMEINSAM BEGEISTERN WIR DEUTSCHLAND!',
      cta: 'Jetzt mitmachen',
    },
    deadline: {
      headline: ['SCHICK UNS BIS ZUM 31.05', 'Deine schönste, aufregendste oder lustigste FULLBALLZEIT-Geschichte.'],
      insta: {
        title: 'So landest Du in unserer Instagram-Story',
        upload: {
          text: 'Lade Dein Lieblingsbild und eine kurze Beschreibung hoch',
        },
        share: {
          prefix: 'Teile das Bild mit Deiner Story anschließend auf Instagram und nutze den Hashtag #FUSSBALLZEIT und verlinke',
          highlight: ' einen unserer zwei Kanäle (',
          dfb: 'dfb_team',
          dfbLink: 'https://www.instagram.com/dfb_team/',
          prefixFussball: ' oder ',
          fussball: 'fussball_de',
          fussballLink: 'https://www.instagram.com/fussball_de/',
          suffix: ')',
        },
        luck: 'Lande mit etwas Glück in unserer Story',
      },
    },
    uploadForm: {
      headline: 'Mitmachen',
      loginHint: 'Melde Dich jetzt an, um Deinen FUSSBALLZEIT-Moment hochzuladen.',
      success: 'Vielen Dank! Dein FUSSBALLZEIT-Moment wurde erfolgreich eingereicht, wird geprüft und zeitnah hochgeladen.',
      error: {
        uploadFailed: 'Du hast bereits an der Aktion teilgenommen. Vielen Dank!',
        noFile: 'Bitte lade deinen FUSSBALLZEIT-Moment hoch.',
        processingFailed: 'Dein FUSSBALLZEIT-Moment konnte nicht verarbeitet werden. Bitte versuche es erneut.',
        limitReached: 'Du hast bereits an der Aktion teilgenommen. Vielen Dank!',
        formField: (field: string) => `${field} ist ein Pflichtfeld.`,
      },
      dnd: {
        text: 'Upload Bild',
        hint: '(Erlaubt sind .jpg und .png bis zu 5 MB im Hochformat)',
        selectedPrefix: 'Ausgewähltes Bild: ',
        error: {
          size: 'Dein ausgewähltes Bild ist zu groß. Maximal erlaubte Dateigröße: 5 MB',
          type: 'Bitte lade nur .jpg oder .png Dateien hoch.',
          dpi: 'Bitte lade nur Bilder mit einer Auflösung von mindestens 300 dpi hoch.',
          aspectRatio: 'Bitte lade ein Bild hoch, das im Hochformat ausgerichtet ist.',
        },
      },
      legal: {
        prefix: 'Ich stimme den',
        link: {
          text: ' Teilnahmebedingungen ',
          href: '/teilnahmebedingungen',
        },
        suffix: 'der Aktion FUSSBALLZEIT IST DIE BESTE ZEIT zu.',
        over18: 'Ich bestätige, dass ich mindestens 18 Jahre alt bin.',
        upload: {
          prefix:
            'Ich willige in die Verarbeitung meiner personenbezogenen Daten im Rahmen des Fußballzeit-Projektes ein. Die Einwilligung ist freiwillig und kann jederzeit mit Wirkung für die Zukunft widerrufen werden. Weitere Informationen finden Sie in der ',
          link: {
            text: 'Datenschutzerklärung',
            href: 'https://www.dfb.de/datenschutzerklaerung/',
          },
          suffix: '.',
        },
      },
      story: {
        placeholderName: 'Maike',
        nameSuffix: ' FUSSBALLZEIT...',
        placeholder: '...hat im Alter von 2 Jahren begonnen – im Trikot vom KFC Uerdingen.',
        maxCharactersHint: ` (max. ${storyMaxCharacters} Zeichen)`,
      },
      cta: 'Abschicken',
    },
    faq: {
      title: 'FAQ',
      items: [
        {
          title: 'Wie kann ich an der Aktion teilnehmen?',
          content: 'Um an der Aktion teilzunehmen, kannst Du ganz einfach  ',
          link: {
            text: 'hier',
            href: '/#mitmachen',
          },
          content2:
            ' Deine beste FUSSBALLZEIT mit uns teilen. Lade dafür Dein favorisiertes Bild hoch und erläutere in einer kurzen Beschreibung, was FUSSBALLZEIT zu deiner besten Zeit macht. Ausführliche Informationen findest du in den ',
          link2: {
            text: 'Teilnahmebedingungen',
            href: '/teilnahmebedingungen',
            newTab: true,
          },
          suffix: '.',
        },
        {
          title: 'Was kann ich hochladen?',
          content:
            'Du kannst ein Foto mit uns teilen, das Deine persönliche FUSSBALLZEIT zeigt. Egal, ob es sich um private Momente oder um Stadionbesuche sowie eigene Aktionen auf dem Feld handelt – alles ist möglich! Hochladen kannst Du einfach JPEGs oder PNGs. Die Datenbeschränkung liegt bei 5 MB. Lade Dein Bild bitte entsprechend in einem Hochformat auf der Seite hoch, da es ansonsten nicht verwendet werden kann.',
        },
        {
          title: 'Gibt es eine Frist für die Einreichung?',
          content: 'Ja, Du kannst Deine beste FUSSBALLZEIT bis zum 31.05.2024 hochladen. Also vergiss nicht, rechtzeitig dabei zu sein!',
        },
        {
          title: 'Welche Preise gibt es zu gewinnen?',
          content: 'Für Deine FUSSBALLZEIT nur das Beste: Wir verlosen 10 x 2 Tickets für die UEFA-Europameisterschaft 2024.',
        },
        {
          title: 'Wie werden die Gewinner*innen ausgewählt?',
          content: 'Die Gewinner*innen für die EM-Tickets werden per Zufallsgenerator ausgewählt.',
        },
        {
          title: 'Kann ich mehrere Bilder & FUSSBALLZEIT-Moment einreichen? ',
          content: 'Nein, Du kannst lediglich ein Bild und einen Text einreichen, um den Wettbewerb für alle Teilnehmer*innen fair zu gestalten.',
        },
        {
          title: 'Wie erfahre ich, ob ich gewonnen habe?',
          content: 'Die Gewinner*innen werden von uns direkt per E-Mail benachrichtigt – behalte Dein Postfach also gut im Auge.',
        },
        {
          title: 'Wer kann an der Aktion teilnehmen?',
          content:
            'Ausnahmslos alle sind herzlich eingeladen, ihre beste FUSSBALLZEIT mit uns zu teilen – vom Gelegenheitsgucker über den Hardcorefan bis hin zur Vollblutstürmerin. Für die Teilnahme musst Du mindestens 18 Jahre alt sein und in Deutschland wohnen, Dein Geschlecht, Deine Konfession oder Deine Nationalität spielen natürlich keine Rolle.',
        },
        {
          title: 'Was passiert mit meinen eingereichten Inhalten?',
          content:
            'Deine eingereichten Inhalte werden ausschließlich im Rahmen der Landingpage veröffentlicht. Bitte stelle sicher, dass Du die Rechte an den eingereichten Inhalten besitzt. Weitere Informationen findest Du in unseren Teilnahmebedingungen. Nur wenn Du Deine Inhalte auf Instagram teilst, den Hashtag #fussballzeit verwendest und einen unserer zwei Kanäle – dfb_team oder fussball_de – verlinkst, besteht die Möglichkeit, dass wir Deinen FUSSBALLZEIT-Moment in unserer Story zeigen.',
        },
        {
          title: 'An wen kann ich mich wenden, wenn ich weitere Fragen habe?',
          content:
            'Wenn Du Fragen hast oder Hilfe benötigst, kontaktiere uns jederzeit über brandmanagement@dfb.de. Unser Team steht Dir gerne zur Verfügung und hilft Dir weiter.',
        },
        {
          title: 'Gibt es Beschränkungen für die Art der eingereichten Inhalte?',
          content:
            'Jegliche Inhalte, die als beleidigend, diskriminierend, gewalttätig oder anderweitig unangemessen betrachtet werden, werden von der Teilnahme ausgeschlossen.',
        },
        {
          title: 'Werden meine persönlichen Daten veröffentlicht?',
          content:
            'Wir respektieren Deine Privatsphäre. Deshalb werden Deine persönlichen Daten, die Du im Rahmen unserer Aktion angibst, ausschließlich für die Durchführung der Aktion verwendet und nicht an Dritte weitergegeben. Weitere Informationen findest Du in unserer ',
          link: {
            text: 'Datenschutzerklärung',
            href: 'https://www.dfb.de/datenschutzerklaerung/',
            newTab: true,
          },
          suffix: '.',
        },
        {
          title: 'Kann ich die Kampagne unterstützen, auch wenn ich nicht teilnehme? ',
          content:
            'Du kannst die Kampagne unterstützen, indem Du sie in Deinen sozialen Netzwerken teilst und Deine Freunde und Familie zum Mitmachen mobilisierst. Jeder Beitrag hilft dabei, Euphorie für die anstehende Europameisterschaft zu entfachen und FUSSBALLZEIT weiterhin zur besten Zeit zu machen. ',
        },
      ],
    },
  },

  admin: {
    evaluate: {
      headline: 'Passwort aktualisieren',
      error: {
        passwordsDontMatch: 'Passwörter stimmen nicht überein',
        save: 'Änderungen konnten nicht gespeichert werden.',
      },
      cta: 'Ändern',
    },
    manualEntry: {
      headline: 'Manueller Eintrag',
      info: 'Die Straße sowie der Ort werden automatisch mit Platzhalter gefüllt, um nicht im CSV-Export berücksichtigt zu werden.',
    },
    moderate: {
      headline: 'Moderation',
    },
    public: {
      headline: 'Öffentlich',
    },
    archive: {
      headline: 'Archiv',
    },
    export: {
      headline: 'Export',
      first150: {
        headline: 'Schnellverlosung (die ersten 150 Einsendungen)',
        exportNamePrefix: 'Fussballzeit_Schnellverlosung_150',
      },
      everybody: {
        headline: 'Alle Kontakte',
        exportNamePrefix: 'Fussballzeit_Alle',
      },
      downloadCTA: 'CSV herunterladen',
    },
    list: {
      empty: 'Keine Einträge vorhanden',
      close: 'Schließen',
      archive: 'Archivieren',
      accept: 'Veröffentlichen',
      sorting: {
        label: 'Sortierung:',
        DESC: 'Datum absteigend',
        ASC: 'Datum aufsteigend',
      },
      acceptModal: {
        text: {
          prefix: 'Bist du sicher, dass du die Story von ',
          suffix: ' veröffentlichen möchtest?',
        },
      },
      storyEdit: {
        error: {
          save: 'Änderungen konnten nicht gespeichert werden.',
        },
      },
      protocol: {
        headline: 'Protokoll',
        from: 'von',
        to: 'zu',
        textChange: {
          prefix: 'Textänderung von',
          previousText: 'Vorheriger Text:',
        },
        userPrefix: 'ausgeführt von',
        userFallback: 'System',
        timestampPrefix: 'um',
        types: {
          VIRUS_SCAN: 'Virenscan',
          MODERATION: 'Moderation',
          PUBLISHED: 'Veröffentlicht',
          ARCHIVED: 'Archiviert',
          QUARANTINE: 'Quarantäne',
        },
      },
    },
    users: {
      headline: 'Benutzer',
      list: {
        headline: 'Liste der Benutzer',
        inactive: ' (inaktiv)',
      },
      create: {
        headline: 'Benutzer erstellen',
        cta: 'Erstellen',
        hint: 'Das System verschickt keine E-Mail an den erstellten Benutzer. Bitte informiere den Benutzer manuell. Beim ersten Login wird der Benutzer aufgefordert, ein neues Passwort zu vergeben.',
        error: 'Benutzer konnte nicht erstellt werden.',
      },
    },
  },

  langSwitcher: {
    toggleLabel: 'Sprache',
  },

  login: {
    headline: 'Willkommen',
    cta: 'Anmelden',
    error: 'Anmeldung fehlgeschlagen. Falsche E-Mail oder Passwort.',
  },

  footer: {
    items: [
      {
        title: `© DFB ${new Date().getFullYear()}`,
      },
      // {
      //   title: 'Nutzungsbedingungen',
      //   href: '/nutzungsbedingungen',
      // },
      {
        title: 'Datenschutzerklärung',
        href: 'https://www.dfb.de/datenschutzerklaerung/',
      },
      {
        title: 'Impressum',
        href: 'https://www.dfb.de/impressum/',
      },
      {
        title: 'Teilnahmebedingungen',
        href: '/teilnahmebedingungen',
      },
    ],
    cookieConsent: {
      title: 'Cookie-Einstellungen',
    },
    social: {
      instagram: 'https://www.instagram.com/dfb_team/',
      linkedin: 'https://www.linkedin.com/company/deutscher-fussball-bund/',
      twitter: 'https://twitter.com/dfb_team',
    },
  },

  terms: {
    headline: 'Teilnahmebedingungen „FUSSBALLZEIT IST DIE BESTE ZEIT “',
    text: 'Für die Durchführung der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ gelten die nachfolgenden Teilnahmebedingungen. Besondere Regelungen ergeben sich aus weiteren DFB-Dokumenten (z.B. DFB-Datenschutzerklärung). ¹',
    updateDate: 'Stand: 29. Februar 2024',
    genderHint:
      '¹ Zur besseren Lesbarkeit von Personenbezeichnungen sowie personenbezogenen Wörtern wird die männliche Form genutzt. Diese Begriffe gelten für alle Geschlechter.',
    items: [
      {
        title: 'Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “',
        items: [
          'Verantwortlich für die Durchführung der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ ist die DFB GmbH & Co. KG. („DFB“) (Kennedyallee 274, 60528 Frankfurt am Main).',
          'Zulässige Inhalte der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ sind unter fussballzeit.dfb.de beschrieben und abrufbar.',
        ],
      },
      {
        title: 'Teilnahme, Beteiligung, Registrierung',
        items: [
          'Teilnahmeberechtigt an der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ sind alle natürlichen mit Wohnsitz in Deutschland lebenden Personen, die das 18. Lebensjahr zu Aktionsbeginn vollendet haben.',
          'Die Teilnahme an der Aktion setzt die erfolgreiche Registrierung unter mein.dfb.de voraus.',
          'Im Rahmen der Aktion sollen Teilnehmer Fotos und Texte einreichen, die einen inhaltlichen Bezug zu der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ haben.',
          'Es gibt keinen Anspruch eines Teilnehmers auf die Veröffentlichung seiner Beiträge auf der Webseite des DFB oder der Social-Media-Kanäle des DFB. Der DFB trifft eine Auswahl, welche eingereichten Inhalte veröffentlicht werden.',
          'Der DFB prüft die jeweils eingereichten Inhalte. Der DFB wird rechtswidrige Inhalte bzw. solche Inhalte von der Aktion ausschließen, die im Widerspruch zu den Werten des DFB stehen.',
          'Ein Rechtsanspruch auf Zulassung zur Teilnahme an der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ besteht nicht. Der DFB kann ohne Angabe von Gründen die Teilnahme an der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ verweigern.',
          'Die Teilnahme beginnt mit dem Hochladen eines Fotos und Einreichen eines Textes, weshalb für den Teilnehmer FUSSBALLZEIT die beste Zeit ist. Das Foto und der Text können bis einschließlich 31.05.2024 auf fussballzeit.dfb.de hochgeladen werden.',
          'Der Text darf bis zu 150 Zeichen lang sein, um für eine Veröffentlichung des Fotos berücksichtigt werden zu können.  Soweit der DFB das hochgeladene Foto und den dazu eingereichten Text veröffentlicht, wird dies unter Angabe des Nutzernamens/Klarnamens des Teilnehmers erfolgen.',
        ],
      },
      {
        title: 'Nutzungsrechte der eingereichten Fotos und Texte',
        items: [
          'Der Teilnehmer sichert dem DFB zu, die erforderlichen Rechte an dem vom Teilnehmer hochgeladenen Foto bzw. Text zu haben. Der Teilnehmer holt, von anderen natürlichen Personen, die auf den Fotos erkennbar sind, eine Einwilligung zur Veröffentlichung der Fotos ein.',
          'Der Teilnehmer räumt dem DFB das nicht ausschließliche, zeitlich, räumlich und inhaltlich unbegrenzte, unwiderrufliche, übertragbare und unterlizenzierbare 	Recht ein, die eingestellten Inhalte (Foto und Text) vollständig oder in Auszügen zu nutzen. Das Recht zur Nutzung der eingestellten Inhalte erstreckt sich auch auf die Veröffentlichung der eingestellten Inhalte auf anderen Portalen des DFB und der Mitgliedsverbände und -vereine des DFB.',
          'Der Teilnehmer verpflichtet sich, vor der Veröffentlichung seiner Inhalte, Beiträge und Themen, diese daraufhin zu überprüfen, ob diese Angaben enthalten, die sie nicht veröffentlichen möchten. Ihre Inhalte, Beiträge und Themen können in Suchmaschinen erfasst und damit weltweit zugreifbar werden. Ein Anspruch auf Löschung oder Korrektur solcher Suchmaschineneinträge gegenüber dem DFB ist ausgeschlossen.',
          'Der Teilnehmer stellt den DFB von sämtlichen Ansprüchen Dritter frei, die auf einer rechtswidrigen Nutzung der bereitgestellten Informationen, Dienste und Daten oder mit seiner Billigung erfolgen, oder die sich insbesondere aus datenschutzrechtlichen, urheberrechtlichen, kunsturheberrechtlichen oder sonstigen rechtlichen Streitigkeiten ergeben, die mit der Teilnahme an der Aktion verbunden sind.',
          'Erlangt ein Teilnehmer Kenntnis darüber, dass eine unbefugte Nutzung des mein DFB-Kontos oder ein sonstiger Verstoß gegen die hier formulierten Bedingungen vorliegt oder vermutet wird, teilen sie dies dem DFB unverzüglich mit.',
        ],
      },
      {
        title: 'Zeitraum der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “',
        items: [
          'Die Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ findet im Zeitraum vom 09.03.2024 bis einschließlich 15.07.2024 („Aktionszeitraum“) statt.',
          'Fotos und Texte können nur bis einschließlich 31.05.2024 auf fussballzeit.dfb.de hochgeladen werden.',
        ],
      },
      {
        title: 'Gewinnpreise/Verlosung',
        items: [
          'Nur die Teilnehmer bekommen die Möglichkeit, an Verlosungen im Rahmen des Aktionszeitraums teilzunehmen.',
          'Die ersten 150 Teilnehmer, die im Zeitraum vom 09.03.2024 bis zum einschließlich 13.03.2024 ein Foto und einen Textbeitrag hochladen, erhalten ein DFB-Trikot nach dem First-Come-First served-Prinzip. Der DFB verlost unter allen Teilnehmern, die bis zum 31.05.2024 ein Foto und einen Text hochgeladen haben, 10 x 2 Tickets für die UEFA-Europameisterschaft 2024. Die Gewinner werden nach einem Zufallsprinzip (Softwarelösung) ausgewählt.',
          'Die ersten 150 Teilnehmer und auch die Gewinner der Verlosung werden über die bei der Registrierung unter mein.dfb.de hinterlegte E-Mail-Adresse benachrichtigt. Ist eine Benachrichtigung über die angegebene E-Mail-Adresse nicht möglich, erlischt der Gewinnanspruch und im Rahmen einer weiteren Ticket-Verlosung werden die Gewinner nach dem Zufallsprinzip ausgelost. Trikots werden in einem solchen Fall - unter Berücksichtigung des First-Come-First served-Prinzips - an den nächstschnellsten Teilnehmer vergeben.',
          'Der DFB behält sich die Durchführung von weiteren Verlosungen während der Dauer der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ vor. Die Gewinner der anderen Verlosungen werden vom Veranstalter nach einem Zufallsprinzip ermittelt.',
          'Nach einer jeden Verlosung wird der erfolgreiche Teilnehmer jeweils über seine bei der Registrierung hinterlegte E-Mail-Adresse über einen Gewinn informiert. Ist eine Gewinnbenachrichtigung über die angegebene E-Mail-Adresse nicht möglich, erlischt der Gewinnanspruch und es wird erneut ein Gewinner ausgelost.',
          'Der DFB behält sich das Recht vor, die Preise bis zum Beginn der Verlosung zu ändern oder zu erweitern.',
        ],
      },
      {
        title: 'Nebenkosten, Steuern, Gebühren',
        items: [
          'Etwaig anfallende Nebenkosten wie Steuern, Versandkosten, die mit der Inanspruchnahme eines Gewinns im Rahmen der Verlosung einhergehen, werden vom DFB übernommen.',
        ],
      },
      {
        title: 'Beendigung der Teilnahme',
        items: [
          'Der Teilnehmer kann seine Teilnahme an der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ jederzeit ohne Einhaltung einer Frist beenden. Es reicht eine E-Mail an brandmanagement@dfb.de. Der Teilnehmer verzichtet in einem solchen Fall auf die Teilnahme an Verlosungen. Durch die Beendigung der Teilnahme werden noch nicht veröffentlichte Fotos und Texte auf Wunsch des Teilnehmers gelöscht.',
        ],
      },
      {
        title: 'Beendigung der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “',
        items: [
          'Der DFB behält sich das Recht vor, die Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ jederzeit einzustellen und/oder durch ein anderes Programm zu ersetzen.',
        ],
      },
      {
        title: 'Ausschluss von der Teilnahme',
        items: [
          'Der DFB behält sich das Recht vor, Teilnehmer von der Teilnahme an der Aktion und der Verlosung(en) auszuschließen, wenn diese in missbräuchlicher Weise Inhalte hochladen.',
        ],
      },
      {
        title: 'Haftung, Datenschutz',
        items: [
          'Für Schäden, die Teilnehmern im Zusammenhang mit ihrer Teilnahme an der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ entstehen, gilt Folgendes: Bei Vorsatz oder grober Fahrlässigkeit sowie bei Vorliegen einer Garantie ist die Haftung unbeschränkt. Bei leichter Fahrlässigkeit ist die Haftung im Falle der Verletzung von Leben, Körper oder Gesundheit ebenfalls unbeschränkt. Bei einer leicht fahrlässigen Verletzung wesentlicher Vertragspflichten ist die Haftung beschränkt auf darauf zurückzuführende Sach- und Vermögensschäden in Höhe des vorhersehbaren, typischerweise eintretenden Schadens. Eine wesentliche Vertragspflicht ist die Pflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der Teilnehmer regelmäßig vertrauen darf. Jede weitergehende Haftung auf Schadensersatz ist – außer für Ansprüche nach dem Produkthaftungsgesetz – ausgeschlossen.',
          'Die mit der Teilnahme verbundenen personenbezogenen Daten des Teilnehmers werden vom DFB zur Durchführung der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ erhoben, verarbeitet und genutzt. Weitere Informationen zur Datenverarbeitung im Zusammenhang mit der Durchführung 	der Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ 	finden 	sich 	unter https://www.dfb.de/datenschutzerklaerung/',
          'Zu diesen Daten gehören insbesondere die vom Teilnehmer bei seiner Anmeldung und sodann im Laufe seiner Teilnahme angegebenen Daten („Stammdaten“) sowie die im Zusammenhang mit seiner Teilnahme anfallenden Daten.',
        ],
      },
      {
        title: 'Änderung der Teilnahmebedingungen',
        items: [
          'Der DFB behält sich das Recht vor, jederzeit Änderungen oder Ergänzungen der Teilnahmebedingungen vorzunehmen, sofern der Teilnehmer hierdurch nicht wider Treu und Glauben benachteiligt wird.',
          'Schadensersatzansprüche von Teilnehmern gegen den DFB wegen gesetzesbedingter länderspezifischer Änderungen sind ausgeschlossen.',
          'Änderungen oder Ergänzungen dieser Teilnahmebedingungen werden durch Benachrichtigung in Textform bekannt gegeben. Die Änderungen oder Ergänzungen der Teilnahmebedingungen gelten als genehmigt, wenn ein Teilnehmer nicht innerhalb von zwei Wochen nach Bekanntgabe in Textform Widerspruch einlegt oder seine Teilnahme am Programm fortsetzt. Die Betreiber werden auf diese Folge bei Bekanntgabe besonders hinweisen. Widerspricht ein Teilnehmer der Änderung oder Ergänzung, so kann seine Teilnahme gemäß den Teilnahmebedingungen beendet werden.',
        ],
      },
      {
        title: 'Recht, Gerichtsstand',
        items: [
          'Es gilt deutsches Recht. Gerichtsstand ist Frankfurt am Main. Darüber hinaus kann ein Teilnehmer auch an seinem allgemeinen Gerichtsstand oder an jedem weiteren Ort, an dem eine gesetzliche Zuständigkeit besteht, gerichtlich in Anspruch genommen werden. Zu einer Teilnahme an einem Schlichtungsverfahren ist der DFB nicht verpflichtet.',
        ],
      },
      {
        title: 'Rückfragen zur Aktion',
        items: ['Rückfragen zur Aktion „FUSSBALLZEIT IST DIE BESTE ZEIT “ können an brandmanagement@dfb.de gerichtet werden.'],
      },
    ],
  },

  generic: {
    salutation: 'Anrede',
    firstname: 'Vorname',
    lastname: 'Nachname',
    firstnameSuffix: 's',
    firstnameSpecialSuffix: "'",
    birthday: 'Geburtsdatum',
    street: 'Straße, Hausnummer',
    companyName: 'Firmenname',
    club: 'Verein (optional)',
    story: 'Story',
    storyTextEdit: {
      cta: 'Bearbeiten',
    },
    legalCheck: 'Teilnahmebedingungen',
    over18Check: 'Altersbestätigung',
    uploadLegalCheck: 'Einwilligung zur Datenverarbeitung',
    city: 'PLZ, Ort',
    mail: 'E-Mail-Adresse',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    createdOn: 'Erstellt am',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    close: 'Schließen',
    send: 'Senden',
    error: 'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.',
    acceptAGB: 'Ich akzeptiere die AGB',
    percent: ' %',
    upload: 'Hochladen',
    login: 'Anmelden',
    logout: 'Abmelden',
  },

  UI: {
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
    selectableTagList: {
      showMore: 'Mehr anzeigen',
      showLess: 'Weniger',
    },
    textarea: {
      max: {
        prefix: 'maximal ',
        suffix: ' Zeichen',
      },
    },
    fileSelect: {
      fileToLargePrefix: 'Die ausgewählte Datei ist zu groß: ',
      allowedFileTypes: 'Erlaubte Dateitypen: ',
      allowedFileSize: 'Max. erlaubte Dateigröße: ',
    },
  },

  resetPassword: {
    passwordsDontMatch: 'Passwörter stimmen nicht überein',
    passwordReset: 'Passwort wurde erfolgreich geändert',
    save: 'Ändern',
    password: 'Passwort',
    passwordRepeat: 'Passwort wiederholen',
    title: 'Passwort ändern',
  },
}

export const langKey = 'DE'

const Default = {
  translation,
  langKey,
  word: 'Deutsch',
}

export default Default
