import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import MediumContainer from '../../UI/Container/MediumContainer'
import Button from '../../UI/Button/Button'

const Quote: React.FC<{}> = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  return (
    <div className="h-fit py-14 lg:py-28 bg-green-primary">
      <MediumContainer>
        <div className="text-md lg:text-xl text-center text-white">{t.index.quote.text}</div>
        <div className="text-md lg:text-xl font-bold mt-5 text-center text-white">{t.index.quote.author}</div>
        <div className="flex justify-center">
          <Button text={t.index.quote.cta} inverted noBorder className="mt-5 lg:py-6" classNameContent="lg:text-md" href="/#mitmachen" />
        </div>
      </MediumContainer>
    </div>
  )
}

export default Quote
