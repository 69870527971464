import { adobeTrackingURL, userCentricsSettingsID } from '../../../../utility'
import { Helmet } from 'react-helmet'

const Tracking: React.FC<{}> = () => {
  return (
    <Helmet>
      <script type="text/plain" src={adobeTrackingURL} data-usercentrics="Adobe Analytics"></script>
      <script type="text/plain" data-usercentrics="Google Tag Manager" async src="https://www.googletagmanager.com/gtag/js?id=AW-955767689"></script>
      <script type="text/plain" data-usercentrics="Google Tag Manager">{`(() => {
        setTimeout(() => {
          console.log('GTM DataLayer pushed')
          window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-955767689'); 
        }
        , 200);
        })()`}</script>
      <script type="text/plain" data-usercentrics="Google Tag Manager">{`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-955767689'); 
        `}</script>
      <script type="text/plain" data-usercentrics="Meta Pixel">
        {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '443643769176748');
        fbq('track', 'PageView');
      `}
      </script>
      {/* <noscript>
        <img height="1" alt="" width="1" style={{ display: 'none' }} src="https://www.facebook.com/tr?id=443643769176748&ev=PageView&noscript=1" />
      </noscript> */}

      <script type="application/javascript" src="https://privacy-proxy.usercentrics.eu/legacy/uc-block.bundle.js"></script>
      <script type="application/javascript">{`(() => {
        setTimeout(() => {
          console.log('Usercentrics CMP', 'Usercentrics CMP loaded')
          if(!!uc) {
            uc.blockOnly(['BJz7qNsdj-7']);
          }
        }
        , 200);
        })()`}</script>
      <script
        type="application/javascript"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        id="usercentrics-cmp"
        data-settings-id={userCentricsSettingsID}
        data-tcf-enabled="true"
        data-eu-mode="true"
      ></script>
    </Helmet>
  )
}

export default Tracking
