import { StyledHeader } from './Header.Styled'
// import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import props from '../../../../redux/props'
import Navigation from './Navigation/Navigation'
// import Container from '../../Container/Container'
import logo from '../../../../assets/image/logo.png'
import { AccountLink } from '@think-internet/zeus-frontend-package/auth/types'
import { useSelector } from 'react-redux'
import props from '../../../../redux/props'

const Header = ({ noNavigation = false }) => {
  const navigate = useNavigate()
  const accountLink: AccountLink = useSelector((s) => s[props.ACCOUNT_LINK])
  const isLoggedIn = () => !!accountLink

  const goHome = () => {
    let path = '/'
    if (isLoggedIn()) {
      path = '/admin/moderate'
    }
    navigate(path)
  }

  return (
    <StyledHeader className="shadow-md">
      {/* <Container> */}
      <div className="w-full px-4 lg:px-10 flex flex-row justify-between items-center gap-10">
        <div onClick={goHome} className="flex flex-row gap-3 items-center cursor-pointer">
          <img src={logo} alt="Deutscher Fussball-Bund" className="h-[72px]" />
        </div>
        <Navigation noNavigation={noNavigation} />
      </div>
      {/* </Container> */}
    </StyledHeader>
  )
}

export default Header
