import { Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import { Level } from '../../types/Auth'
import Moderate from './Moderate/Moderate'
import Public from './Public/Public'
import Archive from './Archive/Archive'
import Export from './Export/Export'
import Users from './Users/Users'
import ManualEntry from './ManualEntry/ManualEntry'

const Admin = () => {
  return (
    <Layout headerTransparent={false} authLevelRequired={[Level.ADMIN]}>
      <Routes>
        <Route path={'moderate'} element={<Moderate />} />
        <Route path={'public'} element={<Public />} />
        <Route path={'archive'} element={<Archive />} />
        <Route path={'export'} element={<Export />} />
        <Route path={'users'} element={<Users />} />
        <Route path={'manual-entry'} element={<ManualEntry />} />
      </Routes>
    </Layout>
  )
}

export default Admin
