import { useSelector } from 'react-redux'
import { Story } from '../../../types/Story'
import Loading from '../Loading/Loading'
import Item from './Item/Item'
import { Mode } from './types'
import props from '../../../redux/props'
import { useState } from 'react'
import Dropdown from '../Dropdown/Dropdown'

type Props = {
  mode: Mode
  stories: Story[] | null
}

enum Sorting {
  ASC = 'ASC',
  DESC = 'DESC',
}

const List: React.FC<Props> = ({ mode, stories }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const [sorting, setSorting] = useState<Sorting>(Sorting.DESC)

  const applySorting = (stories: Story[]) => {
    return stories.sort((a, b) => {
      if (sorting === Sorting.DESC) {
        return a.createdOn < b.createdOn ? 1 : -1
      }
      return a.createdOn > b.createdOn ? 1 : -1
    })
  }

  return (
    <div className="flex flex-col divide-y divide-green-primary gap-5">
      <Loading loading={stories} />
      {!!stories && stories.length === 0 && <div>{t.admin.list.empty}</div>}
      {!!stories && stories.length > 0 && (
        <>
          <div className="flex items-center gap-5">
            <div>{t.admin.list.sorting.label}</div>
            <div>
              <Dropdown
                onChange={setSorting}
                value={sorting}
                items={Object.values(Sorting).map((s) => ({ value: s, label: t.admin.list.sorting[s] }))}
              />
            </div>
          </div>
          {applySorting(stories).map((s) => (
            <Item key={s.uuid} mode={mode} initStory={s} />
          ))}
        </>
      )}
    </div>
  )
}

export default List
