import { useEffect, useState } from 'react'
import { Story } from '../../types/Story'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../redux/routes'

type Query = {
  archived: boolean
  active: boolean
}

const useListStories = (query: Query): Story[] | null => {
  const [stories, setStories] = useState<Story[]>()
  const listStories = functional.use(Routes.LIST_STORIES)

  useEffect(() => {
    const list = async () => {
      const stories = await listStories({ query })
      setStories(stories)
    }
    if (!stories) {
      list()
    }
  }, [stories, listStories, query])

  return stories
}

export default useListStories
