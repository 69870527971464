import { useSelector } from 'react-redux'
import MediumContainer from '../../UI/Container/MediumContainer'
import Headline from '../../UI/Headline/Headline'
import props from '../../../redux/props'
import useTriggerExport, { Type } from '../../hooks/useTriggerExport'
import Button from '../../UI/Button/Button'

const Export = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const triggerExport = useTriggerExport()

  return (
    <MediumContainer className="mb-20 pt-10">
      <Headline>{t.admin.export.headline}</Headline>
      <div className="font-bold text-md mt-5">{t.admin.export.first150.headline}</div>
      <Button
        className="mt-3"
        text={t.admin.export.downloadCTA}
        onClick={() => triggerExport(t.admin.export.first150.exportNamePrefix, Type.FIRST150)}
      />
      <div className="font-bold text-md mt-5">{t.admin.export.everybody.headline}</div>
      <Button
        className="mt-3"
        text={t.admin.export.downloadCTA}
        onClick={() => triggerExport(t.admin.export.everybody.exportNamePrefix, Type.EVERYBODY)}
      />
    </MediumContainer>
  )
}

export default Export
